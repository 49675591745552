import axios from 'axios'
import { isAuthTokenExists, getAuthToken } from '../helpers';

const token = isAuthTokenExists() ? getAuthToken() : '';

export default axios.create({
    // https://chand.club/admin
    // http://127.0.0.1:8000/admin
    baseURL: 'https://chand.club/admin',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
    }
});