import ApiService from './ApiService';

export default {
    all(creds){
        return ApiService.post('/users', creds);
    },
    updateStatus(creds){
        return ApiService.put('/user/status', creds);
    },
    delete(creds){
        return ApiService.delete('/user/delete', {
            data: creds,
        });
    },
    search(creds){
        return ApiService.post('/users/search', creds);
    },
    searchDeposits(creds){
        return ApiService.post('/user/deposits/search', creds);
    },
    transactions(creds){
        return ApiService.post('/user/transactions', creds);
    },
    biddings(creds){
        return ApiService.post('/user/biddings', creds);
    },
    changePassword(creds){
        return ApiService.put('/user/change/password', creds);
    },
    walletAction(creds){
        return ApiService.post('/user/wallet/action', creds);
    },
    highlight(creds){
        return ApiService.put('/user/highlight', creds);
    },
    verifyStatus(creds){
        return ApiService.put('/user/verified', creds);
    }
}