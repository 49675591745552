<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Jantri</h1>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-6 m-auto text-center">
          <form @submit.prevent="getJantriByDate">
            <select v-model="selectedDate">
              <option :value="null" disabled>Select Game Date</option>
              <option
                v-for="day in lastTenDays"
                :key="day.value"
                :value="day.value"
                >{{ day.label }}</option
              >
            </select>
            <select v-model="selectedGameId">
              <option :value="null" disabled>Select Game</option>
              <option v-for="game in games" :key="game._id" :value="game._id">{{
                game.name
              }}</option>
            </select>
            <button
              type="submit"
              class="btn btn-primary"
              style="padding: 0px 10px !important;"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5">
      <div class="col-md-12 mt-4">
        <div class="mb-4 rounded text-center" style="background: #3490DC">
          <h1 class="p-3 text-white">{{ title }}</h1>
        </div>

        <h1 class="text-center">Jodi</h1>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <span class="digit">01</span>
                <span class="amount">₹{{ jodiBets['01'] }}</span>
              </td>
              <td>
                <span class="digit">02</span>
                <span class="amount">₹{{ jodiBets['02'] }}</span>
              </td>
              <td>
                <span class="digit">03</span>
                <span class="amount">₹{{ jodiBets['03'] }}</span>
              </td>
              <td>
                <span class="digit">04</span>
                <span class="amount">₹{{ jodiBets['04'] }}</span>
              </td>
              <td>
                <span class="digit">05</span>
                <span class="amount">₹{{ jodiBets['05'] }}</span>
              </td>
              <td>
                <span class="digit">06</span>
                <span class="amount">₹{{ jodiBets['06'] }}</span>
              </td>
              <td>
                <span class="digit">07</span>
                <span class="amount">₹{{ jodiBets['07'] }}</span>
              </td>
              <td>
                <span class="digit">08</span>
                <span class="amount">₹{{ jodiBets['08'] }}</span>
              </td>
              <td>
                <span class="digit">09</span>
                <span class="amount">₹{{ jodiBets['09'] }}</span>
              </td>
              <td>
                <span class="digit">10</span>
                <span class="amount">₹{{ jodiBets['10'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">11</span>
                <span class="amount">₹{{ jodiBets['11'] }}</span>
              </td>
              <td>
                <span class="digit">12</span>
                <span class="amount">₹{{ jodiBets['12'] }}</span>
              </td>
              <td>
                <span class="digit">13</span>
                <span class="amount">₹{{ jodiBets['13'] }}</span>
              </td>
              <td>
                <span class="digit">14</span>
                <span class="amount">₹{{ jodiBets['14'] }}</span>
              </td>
              <td>
                <span class="digit">15</span>
                <span class="amount">₹{{ jodiBets['15'] }}</span>
              </td>
              <td>
                <span class="digit">16</span>
                <span class="amount">₹{{ jodiBets['16'] }}</span>
              </td>
              <td>
                <span class="digit">17</span>
                <span class="amount">₹{{ jodiBets['17'] }}</span>
              </td>
              <td>
                <span class="digit">18</span>
                <span class="amount">₹{{ jodiBets['18'] }}</span>
              </td>
              <td>
                <span class="digit">19</span>
                <span class="amount">₹{{ jodiBets['19'] }}</span>
              </td>
              <td>
                <span class="digit">20</span>
                <span class="amount">₹{{ jodiBets['20'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">21</span>
                <span class="amount">₹{{ jodiBets['21'] }}</span>
              </td>
              <td>
                <span class="digit">22</span>
                <span class="amount">₹{{ jodiBets['22'] }}</span>
              </td>
              <td>
                <span class="digit">23</span>
                <span class="amount">₹{{ jodiBets['23'] }}</span>
              </td>
              <td>
                <span class="digit">24</span>
                <span class="amount">₹{{ jodiBets['24'] }}</span>
              </td>
              <td>
                <span class="digit">25</span>
                <span class="amount">₹{{ jodiBets['25'] }}</span>
              </td>
              <td>
                <span class="digit">26</span>
                <span class="amount">₹{{ jodiBets['26'] }}</span>
              </td>
              <td>
                <span class="digit">27</span>
                <span class="amount">₹{{ jodiBets['27'] }}</span>
              </td>
              <td>
                <span class="digit">28</span>
                <span class="amount">₹{{ jodiBets['28'] }}</span>
              </td>
              <td>
                <span class="digit">29</span>
                <span class="amount">₹{{ jodiBets['29'] }}</span>
              </td>
              <td>
                <span class="digit">30</span>
                <span class="amount">₹{{ jodiBets['30'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">31</span>
                <span class="amount">₹{{ jodiBets['31'] }}</span>
              </td>
              <td>
                <span class="digit">32</span>
                <span class="amount">₹{{ jodiBets['32'] }}</span>
              </td>
              <td>
                <span class="digit">33</span>
                <span class="amount">₹{{ jodiBets['33'] }}</span>
              </td>
              <td>
                <span class="digit">34</span>
                <span class="amount">₹{{ jodiBets['34'] }}</span>
              </td>
              <td>
                <span class="digit">35</span>
                <span class="amount">₹{{ jodiBets['35'] }}</span>
              </td>
              <td>
                <span class="digit">36</span>
                <span class="amount">₹{{ jodiBets['36'] }}</span>
              </td>
              <td>
                <span class="digit">37</span>
                <span class="amount">₹{{ jodiBets['37'] }}</span>
              </td>
              <td>
                <span class="digit">38</span>
                <span class="amount">₹{{ jodiBets['38'] }}</span>
              </td>
              <td>
                <span class="digit">39</span>
                <span class="amount">₹{{ jodiBets['39'] }}</span>
              </td>
              <td>
                <span class="digit">40</span>
                <span class="amount">₹{{ jodiBets['40'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">41</span>
                <span class="amount">₹{{ jodiBets['41'] }}</span>
              </td>
              <td>
                <span class="digit">42</span>
                <span class="amount">₹{{ jodiBets['42'] }}</span>
              </td>
              <td>
                <span class="digit">43</span>
                <span class="amount">₹{{ jodiBets['43'] }}</span>
              </td>
              <td>
                <span class="digit">44</span>
                <span class="amount">₹{{ jodiBets['44'] }}</span>
              </td>
              <td>
                <span class="digit">45</span>
                <span class="amount">₹{{ jodiBets['45'] }}</span>
              </td>
              <td>
                <span class="digit">46</span>
                <span class="amount">₹{{ jodiBets['46'] }}</span>
              </td>
              <td>
                <span class="digit">47</span>
                <span class="amount">₹{{ jodiBets['47'] }}</span>
              </td>
              <td>
                <span class="digit">48</span>
                <span class="amount">₹{{ jodiBets['48'] }}</span>
              </td>
              <td>
                <span class="digit">49</span>
                <span class="amount">₹{{ jodiBets['49'] }}</span>
              </td>
              <td>
                <span class="digit">50</span>
                <span class="amount">₹{{ jodiBets['50'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">51</span>
                <span class="amount">₹{{ jodiBets['51'] }}</span>
              </td>
              <td>
                <span class="digit">52</span>
                <span class="amount">₹{{ jodiBets['52'] }}</span>
              </td>
              <td>
                <span class="digit">53</span>
                <span class="amount">₹{{ jodiBets['53'] }}</span>
              </td>
              <td>
                <span class="digit">54</span>
                <span class="amount">₹{{ jodiBets['54'] }}</span>
              </td>
              <td>
                <span class="digit">55</span>
                <span class="amount">₹{{ jodiBets['55'] }}</span>
              </td>
              <td>
                <span class="digit">56</span>
                <span class="amount">₹{{ jodiBets['56'] }}</span>
              </td>
              <td>
                <span class="digit">57</span>
                <span class="amount">₹{{ jodiBets['57'] }}</span>
              </td>
              <td>
                <span class="digit">58</span>
                <span class="amount">₹{{ jodiBets['58'] }}</span>
              </td>
              <td>
                <span class="digit">59</span>
                <span class="amount">₹{{ jodiBets['59'] }}</span>
              </td>
              <td>
                <span class="digit">60</span>
                <span class="amount">₹{{ jodiBets['60'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">61</span>
                <span class="amount">₹{{ jodiBets['61'] }}</span>
              </td>
              <td>
                <span class="digit">62</span>
                <span class="amount">₹{{ jodiBets['62'] }}</span>
              </td>
              <td>
                <span class="digit">63</span>
                <span class="amount">₹{{ jodiBets['63'] }}</span>
              </td>
              <td>
                <span class="digit">64</span>
                <span class="amount">₹{{ jodiBets['64'] }}</span>
              </td>
              <td>
                <span class="digit">65</span>
                <span class="amount">₹{{ jodiBets['65'] }}</span>
              </td>
              <td>
                <span class="digit">66</span>
                <span class="amount">₹{{ jodiBets['66'] }}</span>
              </td>
              <td>
                <span class="digit">67</span>
                <span class="amount">₹{{ jodiBets['67'] }}</span>
              </td>
              <td>
                <span class="digit">68</span>
                <span class="amount">₹{{ jodiBets['68'] }}</span>
              </td>
              <td>
                <span class="digit">69</span>
                <span class="amount">₹{{ jodiBets['69'] }}</span>
              </td>
              <td>
                <span class="digit">70</span>
                <span class="amount">₹{{ jodiBets['70'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">71</span>
                <span class="amount">₹{{ jodiBets['71'] }}</span>
              </td>
              <td>
                <span class="digit">72</span>
                <span class="amount">₹{{ jodiBets['72'] }}</span>
              </td>
              <td>
                <span class="digit">73</span>
                <span class="amount">₹{{ jodiBets['73'] }}</span>
              </td>
              <td>
                <span class="digit">74</span>
                <span class="amount">₹{{ jodiBets['74'] }}</span>
              </td>
              <td>
                <span class="digit">75</span>
                <span class="amount">₹{{ jodiBets['75'] }}</span>
              </td>
              <td>
                <span class="digit">76</span>
                <span class="amount">₹{{ jodiBets['76'] }}</span>
              </td>
              <td>
                <span class="digit">77</span>
                <span class="amount">₹{{ jodiBets['77'] }}</span>
              </td>
              <td>
                <span class="digit">78</span>
                <span class="amount">₹{{ jodiBets['78'] }}</span>
              </td>
              <td>
                <span class="digit">79</span>
                <span class="amount">₹{{ jodiBets['79'] }}</span>
              </td>
              <td>
                <span class="digit">80</span>
                <span class="amount">₹{{ jodiBets['80'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">81</span>
                <span class="amount">₹{{ jodiBets['81'] }}</span>
              </td>
              <td>
                <span class="digit">82</span>
                <span class="amount">₹{{ jodiBets['82'] }}</span>
              </td>
              <td>
                <span class="digit">83</span>
                <span class="amount">₹{{ jodiBets['83'] }}</span>
              </td>
              <td>
                <span class="digit">84</span>
                <span class="amount">₹{{ jodiBets['84'] }}</span>
              </td>
              <td>
                <span class="digit">85</span>
                <span class="amount">₹{{ jodiBets['85'] }}</span>
              </td>
              <td>
                <span class="digit">86</span>
                <span class="amount">₹{{ jodiBets['86'] }}</span>
              </td>
              <td>
                <span class="digit">87</span>
                <span class="amount">₹{{ jodiBets['87'] }}</span>
              </td>
              <td>
                <span class="digit">88</span>
                <span class="amount">₹{{ jodiBets['88'] }}</span>
              </td>
              <td>
                <span class="digit">89</span>
                <span class="amount">₹{{ jodiBets['89'] }}</span>
              </td>
              <td>
                <span class="digit">90</span>
                <span class="amount">₹{{ jodiBets['90'] }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="digit">91</span>
                <span class="amount">₹{{ jodiBets['91'] }}</span>
              </td>
              <td>
                <span class="digit">92</span>
                <span class="amount">₹{{ jodiBets['92'] }}</span>
              </td>
              <td>
                <span class="digit">93</span>
                <span class="amount">₹{{ jodiBets['93'] }}</span>
              </td>
              <td>
                <span class="digit">94</span>
                <span class="amount">₹{{ jodiBets['94'] }}</span>
              </td>
              <td>
                <span class="digit">95</span>
                <span class="amount">₹{{ jodiBets['95'] }}</span>
              </td>
              <td>
                <span class="digit">96</span>
                <span class="amount">₹{{ jodiBets['96'] }}</span>
              </td>
              <td>
                <span class="digit">97</span>
                <span class="amount">₹{{ jodiBets['97'] }}</span>
              </td>
              <td>
                <span class="digit">98</span>
                <span class="amount">₹{{ jodiBets['98'] }}</span>
              </td>
              <td>
                <span class="digit">99</span>
                <span class="amount">₹{{ jodiBets['99'] }}</span>
              </td>
              <td>
                <span class="digit">00</span>
                <span class="amount">₹{{ jodiBets['00'] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="text-right font-weight-bolder">Total Jodi Amount: ₹{{ totalJodiBetsAmount }}</h5>
      </div>

      <div class="col-md-12 mt-4">
        <h1 class="text-center">Haraf / Andar</h1>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <span class="digit">0</span>
                <span class="amount">₹{{ andarBets['0'] }}</span>
              </td>
              <td>
                <span class="digit">1</span>
                <span class="amount">₹{{ andarBets['1'] }}</span>
              </td>
              <td>
                <span class="digit">2</span>
                <span class="amount">₹{{ andarBets['2'] }}</span>
              </td>
              <td>
                <span class="digit">3</span>
                <span class="amount">₹{{ andarBets['3'] }}</span>
              </td>
              <td>
                <span class="digit">4</span>
                <span class="amount">₹{{ andarBets['4'] }}</span>
              </td>
              <td>
                <span class="digit">5</span>
                <span class="amount">₹{{ andarBets['5'] }}</span>
              </td>
              <td>
                <span class="digit">6</span>
                <span class="amount">₹{{ andarBets['6'] }}</span>
              </td>
              <td>
                <span class="digit">7</span>
                <span class="amount">₹{{ andarBets['7'] }}</span>
              </td>
              <td>
                <span class="digit">8</span>
                <span class="amount">₹{{ andarBets['8'] }}</span>
              </td>
              <td>
                <span class="digit">9</span>
                <span class="amount">₹{{ andarBets['9'] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="text-right font-weight-bolder">Total Andar Amount: ₹{{ totalAndarBetsAmount }}</h5>
      </div>

      <div class="col-md-12 mt-4">
        <h1 class="text-center">Haraf / Bahar</h1>
        <table class="table table-bordered">
          <tbody>
            <tr>
              <td>
                <span class="digit">0</span>
                <span class="amount">₹{{ baharBets['0'] }}</span>
              </td>
              <td>
                <span class="digit">1</span>
                <span class="amount">₹{{ baharBets['1'] }}</span>
              </td>
              <td>
                <span class="digit">2</span>
                <span class="amount">₹{{ baharBets['2'] }}</span>
              </td>
              <td>
                <span class="digit">3</span>
                <span class="amount">₹{{ baharBets['3'] }}</span>
              </td>
              <td>
                <span class="digit">4</span>
                <span class="amount">₹{{ baharBets['4'] }}</span>
              </td>
              <td>
                <span class="digit">5</span>
                <span class="amount">₹{{ baharBets['5'] }}</span>
              </td>
              <td>
                <span class="digit">6</span>
                <span class="amount">₹{{ baharBets['6'] }}</span>
              </td>
              <td>
                <span class="digit">7</span>
                <span class="amount">₹{{ baharBets['7'] }}</span>
              </td>
              <td>
                <span class="digit">8</span>
                <span class="amount">₹{{ baharBets['8'] }}</span>
              </td>
              <td>
                <span class="digit">9</span>
                <span class="amount">₹{{ baharBets['9'] }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <h5 class="text-right font-weight-bolder">Total Bahar Amount: ₹{{ totalBaharBetsAmount }}</h5>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import GameService from "../services/GameService";
export default {
  name: "Jantri",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      lastTenDays: [],
      games: [],
      selectedDate: null,
      selectedGameId: null,
      jodiBets: {},
      andarBets: {},
      baharBets: {},
      totalJodiBetsAmount: 0,
      totalAndarBetsAmount: 0,
      totalBaharBetsAmount: 0,
      title: '',
    };
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
  },
  methods: {
    loadLastTenDays() {
      const NUM_OF_LAST_DAYS = 7;
      for (let i = 0; i < NUM_OF_LAST_DAYS; i++) {
        let moment = this.moment();
        const date = moment.subtract(i, "day");
        const label = date.format("dddd, Do MMMM");
        const value = date.format("YYYY-MM-DD");
        this.lastTenDays.push({
          label: label,
          value: value,
        });
      }
    },
     loadApiData() {
      GameService.all()
      .then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.games = data.games;
          this.pageLoading = false;
        }
      })
      .then(() => {
          const todaysDate = this.moment().format('YYYY-MM-DD');
        GameService.jantri({
            date: todaysDate,
            gameId: this.games[0]._id,
        })
        .then(response => {
            const { error, return:data } = response.data;
            if(!error && data){
                this.jodiBets = data.jodiBets;
                this.andarBets = data.andarBets;
                this.baharBets = data.baharBets;
                this.totalJodiBetsAmount = data.totalJodiBetsAmount;
                this.totalAndarBetsAmount = data.totalAndarBetsAmount;
                this.totalBaharBetsAmount = data.totalBaharBetsAmount;
                this.title = data.title;
                this.pageLoading = false;
            }
        })
      })
    },
    getJantriByDate(){
        if(this.selectedDate === null || this.selectedGameId === null){
            alert('Please select date and game!');
        }
        else
        {
            GameService.jantri({
                date: this.selectedDate,
                gameId: this.selectedGameId,
            })
            .then(response => {
                const { error, return:data } = response.data;
                if(!error && data){
                    this.jodiBets = data.jodiBets;
                    this.andarBets = data.andarBets;
                    this.baharBets = data.baharBets;
                    this.totalJodiBetsAmount = data.totalJodiBetsAmount;
                    this.totalAndarBetsAmount = data.totalAndarBetsAmount;
                    this.totalBaharBetsAmount = data.totalBaharBetsAmount;
                    this.title = data.title;
                }
            });
        }
    }
  },
   created() {
    this.loadLastTenDays();
    this.loadApiData();
  },
};
</script>

<style scoped>
table thead tr th,
table tbody tr td {
  text-align: center !important;
}

.table-bordered tbody tr td {
  padding: 0.2rem !important;
}

.table-bordered tbody tr td .digit,
.table-bordered tbody tr td .amount {
  font-size: 12px;
  display: block;
}

.table-bordered tbody tr td .digit {
  background: #121833;
  color: #3490dc;
  font-weight: 900;
}

.table-bordered tbody tr td .amount {
  font-weight: 900;
}
</style>
