import ENUM from '../enums'


const state = () => ({
    status: {
        type: ENUM.INIT,
        code: 200,
    },
})

const mutations = {
    SET_API_STATUS(state, status){
        state.status = status;
    }
}

const actions = {

}

const getters = {
    getApiStatus(){
        return state.status;
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}