<template>
      <Page :loading="pageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Change Admin Password</h1>
    </div>

    <div class="container-fluid mt-5">
      
      <div class="row">
        <div class="col-6 m-auto">
           <form @submit.prevent="submitChangePassword">
               <div class="form-group">
          <label for="exampleInputEmail1">Username</label>
          <input
            type="text"
            v-model="username"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Username"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Password</label>
          <input
            type="password"
            v-model="password"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Repeat Password</label>
          <input
            type="password"
            v-model="repeatPassword"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Repeat Password"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">Update</button>
      </form>
          
        </div>
      </div>
    </div>

  </Page>
</template>

<script>
import Page from "../components/Page";
import AuthService from '../services/AuthService';
    export default {
        name: 'Admins',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: false,
                password: null,
                repeatPassword: null,
                username: null,
            }
        },
        
        methods: {
            submitChangePassword(){
                const username = this.username;
                const password = this.password;
                const repeatPassword = this.repeatPassword;

                if(username === null || password === null || password === '' || repeatPassword === null || repeatPassword === ''){
                    alert('Please fill all the fields.');
                }
                else if(password !== repeatPassword){
                    alert('Password must match repeat password.');
                }
                else{
                    AuthService.changeAdminPassword({
                      adminId: this.$store.state.admin.info.id,
                      username: username,
                      password: password,
                    })
                    .then(response => {
                      const { error, return:data } = response.data;
                      if(!error && data){
                        this.username = null;
                        this.password = null;
                        this.repeatPassword = null;
                        this.flash('Admin updated successfully.', 'success', {
                            timeout: 5000,
                        });
                      }
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>