<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">User Biddings</h1>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Game Name</th>
                <th scope="col">Game</th>
                <th scope="col">Type</th>
                <th scope="col">Number</th>
                <th scope="col">Amount</th>
                <th scope="col">Result</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="bidding in biddings" :key="bidding._id">
                <td>
                  {{ moment(bidding.createdAt).format("DD/MM/YYYY h:mm A") }}
                </td>
                <td>{{ bidding.gameName }}</td>
                <td>{{ bidding.game }}</td>
                <td>{{ bidding.type }}</td>
                <td>{{ bidding.number }}</td>
                <td>{{ bidding.amount }}</td>
                <td>{{ bidding.result }}</td>
              </tr>
            </tbody>
          </table>
          <nav v-if="totalCount > perPage" aria-label="Page navigation">
            <ul class="pagination flex-wrap float-right">
              <li class="page-item" v-if="page !== 1" @click="page--">
                <a class="page-link" href="#">«</a>
              </li>
              <li
                class="page-item"
                :class="{ active: pageNumber === page }"
                v-for="pageNumber in pagination(page, totalPagesCount)"
                :key="pageNumber"
                @click="page = pageNumber"
              >
                <a class="page-link" href="#">{{ pageNumber }}</a>
              </li>
              <li
                class="page-item"
                v-if="page < totalPagesCount"
                @click="page++"
              >
                <a class="page-link" href="#">»</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import UserService from "../services/UserService";
export default {
  name: "UserBiddings",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      userId: this.$route.params.userId,
      biddings: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
    };
  },
  computed: {
    getTransactions() {
      return this.transactions;
    },
    isPageLoading() {
      return this.pageLoading;
    },
    totalPagesCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
  },
  methods: {
    loadBiddings(limit, skip) {
      UserService.biddings({
        userId: this.userId,
        limit: limit,
        skip: skip,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.biddings = data.biddings;
          this.totalCount = data.totalBiddings;
          this.pageLoading = false;
        } else {
          this.flash("Something went wrong, Please try again later.", "error", {
            timeout: 5000,
          });
        }
      });
    },
    paginateBiddings(newPageNumber, oldPageNumber) {
      let limit = this.perPage,
        skip = this.page * this.perPage - this.perPage;

      this.pageLoading = true;
      this.loadBiddings(limit, skip);
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 3,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  watch: {
    page(newPageNumber, oldPageNumber) {
      this.paginateBiddings(newPageNumber, oldPageNumber);
    },
  },
  created() {
    this.loadBiddings(this.perPage, 0);
  },
};
</script>

<style scoped></style>
