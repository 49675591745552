<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Bets</h1>   
        </div>

    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-6 m-auto text-center">
                <form @submit.prevent="getBetsByDate">
                    <select v-model="selectedDate">
                        <option :value="null" disabled>Select Game Date</option>
                        <option v-for="day in lastTenDays" :key="day.value" :value="day.value">{{ day.label }}</option>
                    </select>
                    <button type="submit" class="btn btn-primary" style="padding: 0px 10px !important;">Search</button>
                </form>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">Game Name</th>
                            <th scope="col">Betting Amount</th>
                            <th scope="col">Winning Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(bet, index) in bets" :key="index">
                            <td>#{{ index + 1 }}</td>
                            <td>{{ bet.game }}</td>
                            <td>{{ bet.totalBetAmount }}</td>
                            <td>{{ bet.totalWinningAmount }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Total Bet Amount</th>
                            <th scope="col">Total Bet Winning Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{ totalBetAmount }}</td>
                            <td>{{ totalBetWinningAmount }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </Page>
</template>

<script>
    import Page from '../components/Page';
    import GameService from '../services/GameService';
    export default {
        name: 'BetAmounts',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: true,
                lastTenDays: [],
                selectedDate: null,
                bets: [],
                totalBetAmount: 0,
                totalBetWinningAmount: 0,
            }
        },
        computed: {
            isPageLoading(){
                return this.pageLoading;
            }
        },
        methods: {
            loadLastTenDays(){
                const NUM_OF_LAST_DAYS = 7; 
                for (let i = 0; i < NUM_OF_LAST_DAYS; i++) {
                    let moment = this.moment();
                    const date = moment.subtract(i, 'day');
                    const label = date.format('dddd, Do MMMM');
                    const value = date.format('YYYY-MM-DD');
                    this.lastTenDays.push({
                        label: label,
                        value: value,
                    });
                }
            },
            loadTodaysBets(){
                this.totalBetAmount = 0;
                this.totalBetWinningAmount = 0;
                const todaysDate = this.moment().format('YYYY-MM-DD');
                GameService.getGameBets({
                    date: todaysDate,
                })
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.bets = data.bets;
                        this.bets.forEach(bet => {
                                this.totalBetAmount += bet.totalBetAmount;
                                this.totalBetWinningAmount += bet.totalWinningAmount;
                        });
                        this.pageLoading = false;
                    }
                });
            },
            getBetsByDate(){
                this.totalBetAmount = 0;
                this.totalBetWinningAmount = 0;
                const date = this.selectedDate;
                if(date === null){
                    alert('Please select a date!');
                }
                else
                {
                    GameService.getGameBets({
                        date: date,
                    })
                    .then(response => {
                        const { error, return:data } = response.data;
                        if(!error && data){
                            this.bets = data.bets;
                            this.bets.forEach(bet => {
                                this.totalBetAmount += bet.totalBetAmount;
                                this.totalBetWinningAmount += bet.totalWinningAmount;
                            });
                        }
                    });
                }
            }
        },
        created(){
            this.loadLastTenDays();
            this.loadTodaysBets();
        }
    }
</script>

<style scoped>

</style>