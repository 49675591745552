<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">User Transactions</h1>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Transaction</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transaction in getTransactions" :key="transaction._id">
                <td>
                  {{
                    moment(transaction.createdAt).format("DD/MM/YYYY h:mm A")
                  }}
                </td>
                <td>{{ transactionTitle(transaction) }}</td>
                <td>{{ transaction.amount }}</td>
              </tr>
            </tbody>
          </table>
          <nav v-if="totalCount > perPage" aria-label="Page navigation">
            <ul class="pagination flex-wrap float-right">
              <li class="page-item" v-if="page !== 1" @click="page--">
                <a class="page-link" href="#">«</a>
              </li>
              <li
                class="page-item"
                :class="{ active: pageNumber === page }"
                v-for="pageNumber in pagination(page, totalPagesCount)"
                :key="pageNumber"
                @click="page = pageNumber"
              >
                <a class="page-link" href="#">{{ pageNumber }}</a>
              </li>
              <li
                class="page-item"
                v-if="page < totalPagesCount"
                @click="page++"
              >
                <a class="page-link" href="#">»</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import UserService from "../services/UserService";
export default {
  name: "UserTransactions",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      userId: this.$route.params.userId,
      transactions: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
    };
  },
  computed: {
    getTransactions() {
      return this.transactions;
    },
    isPageLoading() {
      return this.pageLoading;
    },
    totalPagesCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
  },
  methods: {
    loadTransactions(limit, skip) {
      UserService.transactions({
        userId: this.userId,
        limit: limit,
        skip: skip,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.transactions = data.transactions;
          this.totalCount = data.totalTransactions;
          this.pageLoading = false;
        } else {
          this.flash("Something went wrong, Please try again later.", "error", {
            timeout: 5000,
          });
        }
      });
    },
    paginateTransactions(newPageNumber, oldPageNumber) {
      let limit = this.perPage,
        skip = this.page * this.perPage - this.perPage;

      this.pageLoading = true;
      this.loadTransactions(limit, skip);
    },
    transactionTitle(transaction) {
      let title = "";
      switch (transaction.type) {
        case "WIN":
          title = `Won bet at ${transaction.data.gameName} (${transaction.data.type}) game.`;
          break;
        case "BET":
          title = `Placed bid in ${transaction.data.gameName} game.`;
          break;
        case "WITHDRAWAL_REQUEST":
          title = `Withdrawal requested by ${transaction.data.type}`;
          break;
        case "DEPOSIT":
          title = this.createDepositTitle(transaction);
          break;
        case "DEPOSIT_REQUEST":
          title = `Deposit request accepted by admin`;
          break;
        case "ADMIN_WALLET_ADD":
          title = `Money added to wallet by admin`;
          break;
        case "ADMIN_WALLET_SUBTRACT":
          title = `Money substracted from wallet by admin`;
          break;
      }
      return title;
    },
    createDepositTitle(transaction) {
      let app = "";
      switch (transaction.data.by) {
        case "com.google.android.apps.nbu.paisa.user":
          app = "GooglePay";
          break;
        case "net.one97.paytm":
          app = "Paytm";
          break;
        case "com.phonepe.app":
          app = "PhonePay";
          break;
        case "IN.ORG.NPCI.UPIAPP":
          app = "BHIMUpi";
          break;
        case "IN.AMAZON.MSHOP.ANDROID.SHOPPING":
          app = "AmazonPay";
          break;
        case "RAZORPAY":
          app = "RazorPay";
          break;
      }
      const title = `Deposited by ${app}`;
      return title;
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 3,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  watch: {
    page(newPageNumber, oldPageNumber) {
      this.paginateTransactions(newPageNumber, oldPageNumber);
    },
  },
  created() {
    this.loadTransactions(this.perPage, 0);
  },
};
</script>

<style scoped></style>
