import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import * as helpers from './helpers'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import moment from 'moment'
import VueSocketIO from 'vue-socket.io'
import VueFlashMessage from 'vue-flash-message';
require('vue-flash-message/dist/vue-flash-message.min.css');

Vue.use(VueFlashMessage);
// import SocketIO from 'socket.io-client' 

// const options = { path: '/my-app/' }; //Options object to pass into SocketIO

// https://chand.club:8001
// http://127.0.0.1:8001
Vue.use(new VueSocketIO({
    debug: false,
    connection: 'https://chand.club:8001', //options object is Optional
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);


import '@/GlobalComponents';

const Plugins = {
  install(Vue) {
      Vue.helpers = helpers;
      Vue.prototype.$helpers = helpers;
      Vue.prototype.moment = moment;
      // http://127.0.0.1:8000/
      // https://chand.club/
      Vue.prototype.$baseUrl = 'https://chand.club/';
  }
}

Vue.prototype.$eventBus = new Vue();

Vue.use(Plugins);

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.authenticationRequired)){
    // if token exists in localstorage and admin state is filled
    if(helpers.isAuthTokenExists()){
      next();
      return;
    }
    // if token and admin state is empty
    else{
      next('/login');
    }

  }

  next();

});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
