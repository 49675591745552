<template>
    <Loader />
</template>

<script>
    export default {
        created(){
          this.$store.dispatch('admin/setAdminInfo');
        }
    }
</script>

<style scoped>
    
</style>