import ApiService from './ApiService';

export default {
    all(){
        return ApiService.get('/banners');
    },
    create(creds){
        return ApiService.post('/banners', creds);
    },
    delete(creds){
        return ApiService.delete('/banners', {
            data: creds
        });
    }

}