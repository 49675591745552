import ApiService from './ApiService';

export default {
    all(){
        return ApiService.get('/settings');
    },
    update(creds){
        return ApiService.put('/setting', creds);      
    }

}