import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin'
import api from './modules/api' 


Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    admin,
    api
  }
})
