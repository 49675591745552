import AuthService from '@/services/AuthService'
import ENUM from '../enums'

const state = () => ({
    info: null,
    onlineUsers: [],
    supportTicketsCount: 0,
    depositRequestsCount: 0,
    bankWithdrawalRequestsCount: 0,
    paytmWithdrawalRequestsCount: 0,
    upiWithdrawalRequestsCount: 0,
});

const mutations = {
  SET_ADMIN_INFO(state, info) {
      state.info = info;
  },
  SET_ADMIN_ONLINE_USERS(state, users){
    state.onlineUsers = users;
  },
  SET_SUPPORT_TICKETS_COUNT(state, count){
    state.supportTicketsCount = count;
  },
  SET_DEPOSIT_REQUESTS_COUNT(state, count){
    state.depositRequestsCount = count;
  },
  SET_BANK_WITHDRAWAL_REQUESTS_COUNT(state, count){
    state.bankWithdrawalRequestsCount = count;
  },
  SET_PAYTM_WITHDRAWAL_REQUESTS_COUNT(state, count){
    state.paytmWithdrawalRequestsCount = count;
  },
  SET_UPI_WITHDRAWAL_REQUESTS_COUNT(state, count){
    state.upiWithdrawalRequestsCount = count;
  }
}

const actions = {
    setAdminInfo({ commit }) {
        AuthService.getAdminInfo()
                .then(response => {
                  const { error, return:data } = response.data;
                          if(!error && data){
                            commit('SET_ADMIN_INFO', {
                              id: data.admin._id,
                              username: data.admin.username,
                            });
                            commit('api/SET_API_STATUS', {type: ENUM.LOADED, code: 200}, { root: true });
                            commit('SET_SUPPORT_TICKETS_COUNT', data.ticketsCount);
                            commit('SET_DEPOSIT_REQUESTS_COUNT', data.depositRequestsCount);
                            commit('SET_BANK_WITHDRAWAL_REQUESTS_COUNT', data.bankWithdrawalRequestsCount);
                            commit('SET_PAYTM_WITHDRAWAL_REQUESTS_COUNT', data.paytmWithdrawalRequestsCount);
                            commit('SET_UPI_WITHDRAWAL_REQUESTS_COUNT', data.upiWithdrawalRequestsCount);

                          }
                })
                .catch(error => {
                  commit('api/SET_API_STATUS', {
                      type: ENUM.ERROR,
                      code: error.response.status,
                  }, { root: true });
                });
      },
      setAdminOnlineUsers({ commit }, users){
        commit('SET_ADMIN_ONLINE_USERS', users);
      },
      setSupportTicketsCount({ commit }, count){
        commit('SET_SUPPORT_TICKETS_COUNT', count);
      },
      setDepositRequestsCount({ commit }, count){
        commit('SET_DEPOSIT_REQUESTS_COUNT', count); 
      },
      setBankWithdrawalRequestsCount({ commit }, count){
        commit('SET_BANK_WITHDRAWAL_REQUESTS_COUNT', count); 
      },
      setPaytmWithdrawalRequestsCount({ commit }, count){
        commit('SET_PAYTM_WITHDRAWAL_REQUESTS_COUNT', count); 
      },
      setUpiWithdrawalRequestsCount({ commit }, count){
        commit('SET_UPI_WITHDRAWAL_REQUESTS_COUNT', count); 
      },
}

const getters = {
    onlineUsersCount: state => {
      return state.onlineUsers.length;
    },
    getOnlineUsers: state => {
      return state.onlineUsers;
    },
    getSupportTicketsCount: state => {
      return state.supportTicketsCount;
    },
    getDepositRequestsCount: state => {
      return state.depositRequestsCount;
    },
    getBankWithdrawalRequestsCount: state => {
      return state.bankWithdrawalRequestsCount;
    },
    getPaytmWithdrawalRequestsCount: state => {
      return state.paytmWithdrawalRequestsCount;
    },
    getUpiWithdrawalRequestsCount: state => {
      return state.upiWithdrawalRequestsCount;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}