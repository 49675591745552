<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Banners</h1>
      <input
        class="d-none"
        ref="bannerFile"
        @change="onBannerSelect"
        type="file"
      />
      <button @click="selectBanner">Create</button>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Banner</th>
                <th scope="col">Uploaded</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody v-if="banners.length">
              <tr v-for="(banner, index) in banners" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>
                  <a target="_blank" :href="getBanner(banner.name)">
                    <img
                      class="banner_img"
                      :src="getBanner(banner.name)"
                      alt=""
                    />
                  </a>
                </td>
                <td>{{ moment(banner.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="deleteBanner(index)"
                    class="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="text-danger" valign="top" colspan="8">
                  No banners available in the table!
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import BannerService from "../services/BannerService";
export default {
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      banners: [],
      selectedFile: "",
    };
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
  },
  methods: {
    getBanner(banner) {
      return this.$baseUrl + "banners/" + banner;
    },
    getAllBanners() {
      BannerService.all().then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.banners = data.banners;
          this.pageLoading = false;
        }
      });
    },
    selectBanner() {
      let fileInputElement = this.$refs.bannerFile;
      fileInputElement.click();
    },
    onBannerSelect(event) {
      const selectedFile = event.target.files[0];
      // 5MB
      const maxFileSize = 5 * 1024 * 1024;
      const allowedFileExtensions = ["image/jpg", "image/jpeg", "image/png"];

      if (!allowedFileExtensions.includes(selectedFile.type)) {
        alert("Please upload banner with .jpg, .jpeg, .png extension.");
      } else if (selectedFile.size > maxFileSize) {
        alert("Banner size must be less then 5MB.");
      } else {
        this.selectedFile = selectedFile;
        this.uploadBanner();
      }
    },
    uploadBanner() {
      let formData = new FormData();
      formData.append("file", this.selectedFile);
      BannerService.create(formData).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.banners.push(data.banner);
          this.flash("Banner created successfully.", "success", {
              timeout: 5000,
            });
        }
      });
    },
    deleteBanner(index) {
      if (confirm("Are you sure you want to delete this banner?")) {
        BannerService.delete({
          bannerId: this.banners[index]._id,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.banners.splice(index, 1);
            this.flash("Banner deleted successfully.", "success", {
              timeout: 5000,
            });
          }
        })
      }
    },
  },
  created() {
    this.getAllBanners();
  },
};
</script>

<style scoped>
.banner_img {
  width: 100px;
  height: 100px;
}
</style>
