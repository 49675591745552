<template>
  <Page :loading="pageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Users</h1>
      <a class="btn btn-primary" target="_blank" :href="getDownloadLink()"
        >Bulk OTP</a
      >
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-8 m-auto">
          <div class="form-group">
            <label
              for="userSeachInput"
              class="d-block text-white text-center h5"
              >Search Users</label
            >
            <input
              type="text"
              class="form-control"
              @keyup="searchUser($event)"
              id="userSeachInput"
              aria-describedby="helpId"
              placeholder="Search users by name, id or mobile..."
            />
          </div>
          <div class="form-check mb-3 text-center">
            <input
              type="checkbox"
              v-model="userByWalletFilter"
              @change="filterUserByWalletBalance($event)"
              class="form-check-input"
              id="exampleCheck1"
            />
            <label class="form-check-label text-white" for="exampleCheck1"
              >By Wallet Balance</label
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div v-if="isSearchingUser" class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Verified</th>
                  <th scope="col">Wallet</th>
                  <th scope="col">Status</th>
                  <th scope="col">Joined</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :style="[
                    user.highlighted
                      ? { background: '#fb8888' }
                      : { background: 'transparent' },
                  ]"
                  v-for="(user, index) in getSearchedUsers"
                  :key="index"
                >
                  <td>{{ user.name }}</td>
                  <td>{{ user.mobile }}</td>
                  <td>{{ user.verified }}</td>
                  <td>{{ user.wallet }}</td>
                  <td>{{ user.active ? "Active" : "Blocked" }}</td>
                  <td>{{ moment(user.createdAt).format("DD/MM/YYYY") }}</td>
                  <td>
                    <select @change="getAction(user._id, $event)">
                      <option selected="">Select Action</option>
                      <option value="TRANSACTIONS">Transactions</option>
                      <option value="BIDDINGS">Biddings</option>
                      <option value="CHANGE_PASSWORD">Change Password</option>
                      <option v-if="!user.highlighted" value="HIGHLIGHT"
                        >Highlight</option
                      >
                      <option v-if="user.highlighted" value="REMOVE_HIGHLIGHT"
                        >Unhighlight</option
                      >
                      <option value="ADD_BALANCE">Add Money</option>
                      <option value="SUBSTRACT_BALANCE">Debit Money</option>
                      <option v-if="!user.verified" value="VERIFY_USER"
                        >Verify User</option
                      >
                      <option v-if="user.verified" value="UNVERIFY_USER"
                        >Unverify User</option
                      >
                      <option v-if="user.active" value="BLOCK">Block</option>
                      <option v-if="!user.active" value="UNBLOCK"
                        >Unblock</option
                      >
                      <option value="DELETE">Delete</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="!isSearchingUser" class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Mobile</th>
                  <th scope="col">Verified</th>
                  <th scope="col">Wallet</th>
                  <th scope="col">Status</th>
                  <th scope="col">Joined</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :style="[
                    user.highlighted
                      ? { background: '#fb8888' }
                      : { background: 'transparent' },
                  ]"
                  v-for="(user, index) in getUsers"
                  :key="index"
                >
                  <td>{{ user.name }}</td>
                  <td>{{ user.mobile }}</td>
                  <td>{{ user.verified }}</td>
                  <td>{{ user.wallet }}</td>
                  <td>{{ user.active ? "Active" : "Blocked" }}</td>
                  <td>{{ moment(user.createdAt).format("DD/MM/YYYY") }}</td>
                  <td>
                    <select @change="getAction(user._id, $event)">
                      <option selected="">Select Action</option>
                      <option value="TRANSACTIONS">Transactions</option>
                      <option value="BIDDINGS">Biddings</option>
                      <option value="CHANGE_PASSWORD">Change Password</option>
                      <option v-if="!user.highlighted" value="HIGHLIGHT"
                        >Highlight</option
                      >
                      <option v-if="user.highlighted" value="REMOVE_HIGHLIGHT"
                        >Unhighlight</option
                      >
                      <option value="ADD_BALANCE">Add Money</option>
                      <option value="SUBSTRACT_BALANCE">Debit Money</option>
                      <option v-if="!user.verified" value="VERIFY_USER"
                        >Verify User</option
                      >
                      <option v-if="user.verified" value="UNVERIFY_USER"
                        >Unverify User</option
                      >
                      <option v-if="user.active" value="BLOCK">Block</option>
                      <option v-if="!user.active" value="UNBLOCK"
                        >Unblock</option
                      >
                      <option value="DELETE">Delete</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <nav v-if="!isSearchingUser" aria-label="Page navigation">
            <ul class="pagination flex-wrap float-right">
              <li class="page-item" v-if="page !== 1" @click="page--">
                <a class="page-link" href="#">«</a>
              </li>
                <li
                  class="page-item"
                  :class="{ active: pageNumber === page }"
                  v-for="pageNumber in pagination(page, totalPagesCount)"
                  :key="pageNumber"
                  @click="page = pageNumber"
                >
                  <a class="page-link" href="#">{{ pageNumber }}</a>
                </li>
              <li
                class="page-item"
                v-if="page < totalPagesCount"
                @click="page++"
              >
                <a class="page-link" href="#">»</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>

    <Modal
      v-if="changePasswordModal"
      :showModal="changePasswordModal"
      :title="'Change Password'"
    >
      <form @submit.prevent="submitChangePassword">
        <div class="form-group">
          <label for="exampleInputEmail1">Password</label>
          <input
            type="password"
            v-model="password"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Password"
          />
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Repeat Password</label>
          <input
            type="password"
            v-model="repeatPassword"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Repeat Password"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">Update</button>
      </form>
    </Modal>

    <Modal
      v-if="walletActiondModal"
      :showModal="walletActiondModal"
      :title="walletActionTitle"
    >
      <form @submit.prevent="submitWalletAction">
        <div class="form-group">
          <label for="exampleInputPassword1">Amount</label>
          <input
            type="number"
            v-model="walletActionAmount"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Amount"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">
          {{ walletActionButton }}
        </button>
      </form>
    </Modal>
  </Page>
</template>

<script>
import Page from "../components/Page";
import UserService from "../services/UserService";
export default {
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      changePasswordModal: false,
      changePasswordUser: "",
      password: null,
      repeatPassword: null,
      walletActiondModal: false,
      walletAction: "",
      walletActionUser: "",
      walletActionAmount: null,
      users: [],
      page: 1,
      perPage: 10,
      totalCount: 0,
      searchingUser: false,
      searchedUsers: [],
      userByWalletFilter: false,
    };
  },
  computed: {
    getUsers() {
      return this.users;
    },
    totalPagesCount() {
      return Math.ceil(this.totalCount / this.perPage);
    },
    isSearchingUser() {
      return this.searchingUser;
    },
    getSearchedUsers() {
      return this.searchedUsers;
    },
    walletActionTitle() {
      let title = "";
      switch (this.walletAction) {
        case "ADD":
          title = "Add Balance";
          break;
        case "SUBSTRACT":
          title = "Debit Balance";
          break;
      }
      return title;
    },
    walletActionButton() {
      let title = "";
      switch (this.walletAction) {
        case "ADD":
          title = "Add";
          break;
        case "SUBSTRACT":
          title = "Debit";
          break;
      }
      return title;
    },
  },
  methods: {
    getDownloadLink() {
      return (
        this.$baseUrl + "admin/download?type=" + "bulksms" + "&file=" + "csv"
      );
    },
    hideModal() {
      this.changePasswordModal = false;
      this.walletActiondModal = false;
    },
    loadUsers(limit, skip, filterByWallet = false) {
      UserService.all({
        limit: limit,
        skip: skip,
        filterByWallet: filterByWallet,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.users = data.users;
          this.totalCount = data.totalUsers;
          this.pageLoading = false;
        } else {
          this.flash("Something went wrong, Please try again later.", "error", {
            timeout: 5000,
          });
        }
      });
    },
    paginateUsers(newPageNumber, oldPageNumber) {
      let limit = this.perPage,
        skip = this.page * this.perPage - this.perPage;

      this.pageLoading = true;
      this.loadUsers(limit, skip, this.userByWalletFilter);
    },
    getAction(id, event) {
      const action = event.target.value;
      switch (action) {
        case "BLOCK":
          if (confirm("Are you sure you want to block this user?"))
            this.changeStatus(id, false);
          break;
        case "UNBLOCK":
          if (confirm("Are you sure you want to unblock this user?"))
            this.changeStatus(id, true);
          break;
        case "DELETE":
          if (confirm("Are you sure you want to delete this user?"))
            this.deleteUser(id);
          break;
        case "TRANSACTIONS":
          this.$router.push("user/transactions/" + id);
          break;
        case "BIDDINGS":
          this.$router.push("user/biddings/" + id);
          break;
        case "CHANGE_PASSWORD":
          this.changePasswordUser = id;
          this.changePasswordModal = true;
          break;
        case "ADD_BALANCE":
          this.walletActionUser = id;
          this.walletAction = "ADD";
          this.walletActiondModal = true;
          break;
        case "SUBSTRACT_BALANCE":
          this.walletActionUser = id;
          this.walletAction = "SUBSTRACT";
          this.walletActiondModal = true;
          break;
        case "HIGHLIGHT":
          if (confirm("Are you sure you want to highlight this user?"))
            this.highlightUser(id, true);
          break;
        case "REMOVE_HIGHLIGHT":
          if (confirm("Are you sure you want to unhighlight this user?"))
            this.highlightUser(id, false);
          break;
        case "VERIFY_USER":
          this.userVerifiedStatus(id, true);
          break;
        case "UNVERIFY_USER":
          this.userVerifiedStatus(id, false);
          break;
      }
    },
    changeStatus(id, status) {
      UserService.updateStatus({
        userId: id,
        active: status,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.$set(
            this.users,
            this.users.findIndex((user) => user._id === id),
            data.user
          );
          var flashMessage = status
            ? "User unblocked successfully."
            : "User blocked successfully.";
          this.flash(flashMessage, "success", {
            timeout: 5000,
          });
        } else {
          this.flash("Something went wrong, Please try again later.", "error", {
            timeout: 5000,
          });
        }
      });
    },
    deleteUser(id) {
      UserService.delete({
        userId: id,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.users.splice(
            this.users.findIndex((user) => user._id === id),
            1
          );
          this.flash("User deleted successfully.", "success", {
            timeout: 5000,
          });
        } else {
          this.flash("Something went wrong, Please try again later.", "error", {
            timeout: 5000,
          });
        }
      });
    },
    searchUser(event) {
      const queryString = event.target.value.trim();
      this.searchingUser = queryString !== "" ? true : false;
      if (queryString !== "") {
        UserService.search({
          query: queryString,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.searchedUsers = data.users;
          }
        });
      }
    },
    submitChangePassword() {
      const passwordLength = this.password.split("").length;
      if (this.password === null || this.repeatPassword === null) {
        alert("Password and repeat password is required.");
      } else if (this.password !== this.repeatPassword) {
        alert("Password and repeat password does not match.");
      } else if (passwordLength < 4 || passwordLength > 16) {
        alert("Password must be between 4-16 charecters.");
      } else {
        UserService.changePassword({
          userId: this.changePasswordUser,
          password: this.password,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.password = null;
            this.repeatPassword = null;
            this.changePasswordUser = "";
            this.flash("Password changed successfully.", "success", {
              timeout: 5000,
            });
            this.hideModal();
          }
        });
      }
    },
    submitWalletAction() {
      if (this.walletActionAmount === null) {
        alert("Amount cannot be empty.");
      } else {
        UserService.walletAction({
          userId: this.walletActionUser,
          action: this.walletAction,
          amount: this.walletActionAmount,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            const message =
              this.walletAction === "ADD" ? "added" : "substracted";
            this.walletAction = "";
            this.walletActionAmount = null;
            this.walletActionUser = "";
            this.$set(
              this.users,
              this.users.findIndex((user) => user._id === data.user._id),
              data.user
            );
            this.flash(`Money ${message} successfully.`, "success", {
              timeout: 5000,
            });
            this.hideModal();
          }
        });
      }
    },
    highlightUser(userId, highlight) {
      UserService.highlight({
        userId: userId,
        highlight: highlight,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.$set(
            this.users,
            this.users.findIndex((user) => user._id === data.user._id),
            data.user
          );
          this.flash(`User highlighted successfully.`, "success", {
            timeout: 5000,
          });
        }
      });
    },
    userVerifiedStatus(userId, status) {
      UserService.verifyStatus({
        userId: userId,
        status: status,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.$set(
            this.users,
            this.users.findIndex((user) => user._id === data.user._id),
            data.user
          );
          const alertMessage = status
            ? "User verified successfully!"
            : "User unverified successfully!";
          this.flash(alertMessage, "success", {
            timeout: 5000,
          });
        }
      });
    },
    filterUserByWalletBalance(event) {
      if (event.target.checked) {
        this.loadUsers(this.perPage, 0, true);
      } else {
        this.loadUsers(this.perPage, 0);
      }
    },
    pagination(c, m) {
      var current = c,
        last = m,
        delta = 3,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) { 
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  watch: {
    page(newPageNumber, oldPageNumber) {
      this.paginateUsers(newPageNumber, oldPageNumber);
    },
  },
  async created() {
    this.$eventBus.$on("hideModal", this.hideModal);
    this.loadUsers(this.perPage, 0);
  },
  beforeDestroy() {
    this.$eventBus.$off("hideModal");
  },
};
</script>

<style scoped></style>
