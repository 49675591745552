<template>
    <Page :loading="false">
    <!-- <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    > -->
      <!-- <h1 class="h2 w-100 text-center">Support Tickets</h1> -->
    <!-- </div> -->

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-8 m-auto">
            <h1 class="text-center">404</h1>
            <h1 class="text-center font-weight-bolder display-h1">Page Not Found</h1>
        </div>
      </div>
    </div>


  </Page>
</template>

<script>
import Page from "../components/Page";
    export default {
        name: 'PageNotFound',
        components: {
            Page,
        }
    }
</script>

<style  scoped>

</style>