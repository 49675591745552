import ApiService from './ApiService';

export default {
    login(cred){
        return ApiService.post('/login', cred);
    },
    getAdminInfo(){
        return ApiService.get('/me');
    },
    getDashboardInfo(){
        return ApiService.get('/dashboard');
    },
    getUsers(creds){
        return ApiService.post('/test', creds);
    },
    changeAdminPassword(creds){
        return ApiService.put('/change-password', creds);
    },
    sendPushNotification(creds){
        return ApiService.post('/push-notification', creds);
    }
}