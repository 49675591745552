import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './views/Login'
import Dashboard from '../src/views/Dashboard.vue'
import Games from '../src/views/Games.vue'
import Users from '../src/views/Users.vue'
import Settings from '../src/views/Settings.vue'
import Banners from '../src/views/Banners.vue'
import DepositTiming from '../src/views/DepositTiming.vue';
import WithdrawalTiming from '../src/views/WithdrawalTiming.vue';
import SupportTicket from '../src/views/SupportTicket.vue';
import DepositRequests from '../src/views/DepositRequests.vue';
import WithdrawalRequests from '../src/views/WithdrawalRequests.vue';
import BetAmounts from '../src/views/BetAmounts.vue';
import Jantri from '../src/views/Jantri.vue';
import Results from '../src/views/Results.vue';
import Links from '../src/views/Links.vue';
import UserTransactions from '../src/views/UserTransactions.vue';
import UserBiddings from '../src/views/UserBiddings.vue';
import Admins from '../src/views/Admins.vue';
import DepositHistory from '../src/views/DepositHistory';
import WithdrawalHistory from '../src/views/WithdrawalHistory';
import Winners from '../src/views/Winners';
import PushNotification from '../src/views/PushNotification';
import PageNotFound from '../src/views/PageNotFound';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      authenticationRequired: false
    },
  },
  {
    path: '/games',
    name: 'games',
    component: Games,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/banners',
    name: 'banners',
    component: Banners,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/deposit-timing',
    name: 'deposit-timing',
    component: DepositTiming,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/withdrawal-timing',
    name: 'withdrawal-timing',
    component: WithdrawalTiming,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/support-tickets',
    name: 'support-tickets',
    component: SupportTicket,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/deposit-requests',
    name: 'deposit-requests',
    component: DepositRequests,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/withdrawal-requests/:type',
    name: 'withdrawal-requests',
    component: WithdrawalRequests,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/bets',
    name: 'bets',
    component: BetAmounts,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/jantri',
    name: 'jantri',
    component: Jantri,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/results',
    name: 'results',
    component: Results,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/links',
    name: 'links',
    component: Links,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/user/transactions/:userId',
    name: 'user-transactions',
    component: UserTransactions,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/user/biddings/:userId',
    name: 'user-biddings',
    component: UserBiddings,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/admins',
    name: 'admins',
    component: Admins,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/deposits',
    name: 'deposit-history',
    component: DepositHistory,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/withdrawals',
    name: 'withdrawal-history',
    component: WithdrawalHistory,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/winners',
    name: 'winners',
    component: Winners,
    meta: {
      authenticationRequired: true
    },
  },
  {
    path: '/push-notification',
    name: 'push-notification',
    component: PushNotification,
    meta: {
      authenticationRequired: true
    },
  },
  { path: "*", component: PageNotFound }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
