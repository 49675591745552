const AUTH_TOKEN_KEY = 'authToken';

export function isAuthTokenExists() {
    return (localStorage.getItem(AUTH_TOKEN_KEY) === null) ? false : true;
}

export function getAuthToken() {
    return localStorage.getItem(AUTH_TOKEN_KEY)
}

export function setAuthToken(token) {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
}

export function deleteAuthToken() {
    localStorage.removeItem(AUTH_TOKEN_KEY);
}
