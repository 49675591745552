<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Results</h1>
        </div>

        <div class="container">
    <div class="row">
        <div class="col-md-6 text-center border">
            <h3 class="mt-3">Publish Game Result</h3>
            <form class="px-3 pb-2 pt-2" @submit.prevent="publishResult">
                <div class="form-group">
                    <select class="form-control" v-model="selectedGameId">
                        <option :value="null" disabled>Select Game</option>
                        <option v-for="game in games" :key="game._id" :value="game._id">{{
                            game.name
                        }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <select class="form-control" v-model="selectedDate">
                        <option :value="null" disabled>Select Game Date</option>
                        <option
                            v-for="day in lastTenDays"
                            :key="day.value"
                            :value="day.value"
                            >{{ day.label }}</option
                        >
                    </select>
                </div>

                <div class="form-group">
                    <input class="form-control" v-model="selectedWinningNumber" type="text" placeholder="Winning number">
                </div>
                <button class="btn btn-primary btn-block" :disabled="formProcessing">{{ formProcessing ? '...' : 'Publish' }}</button>
            </form>
        </div>
        <div class="col-md-6 text-center border">
            <h3 class="mt-3">Game Results</h3>
            <table class="table table-bordered">
                <thead>
                    <tr>
                    <th scope="col">Game Name</th>
                    <th scope="col">Number</th>
                    <th scope="col">Date</th>
                    <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                        <tr v-for="result in results" :key="result._id">
                            <td>{{ result.gameName }}</td>
                            <td>{{ result.number }}</td>
                            <td>{{ moment(result.date).format('DD/MM/YYYY') }}</td>
                            <td>
                                <button type="button" @click="restoreResult(result._id)" class="btn btn-primary">Restore</button>
                            </td>
                        </tr>
                             
                </tbody>
            </table>
        </div>
    </div>
</div>
    </Page>
</template>

<script>
    import Page from '../components/Page';
    import GameService from "../services/GameService";
    export default {
        name: 'Results',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: false,
                lastTenDays: [],
                games: [],
                results: [],
                selectedGameId: null,
                selectedDate: null,
                selectedWinningNumber: null,
                formProcessing: false,
            }
        },
        computed:{
            isPageLoading(){
                return this.pageLoading;
            }
        },
        methods: {
            loadLastTenDays() {
                const NUM_OF_LAST_DAYS = 7;
                for (let i = 0; i < NUM_OF_LAST_DAYS; i++) {
                    let moment = this.moment();
                    const date = moment.subtract(i, "day");
                    const label = date.format("dddd, Do MMMM");
                    const value = date.format("YYYY-MM-DD");
                    this.lastTenDays.push({
                    label: label,
                    value: value,
                    });
                }
            },
            loadGames(){
                GameService.all()
                .then((response) => {
                    const { error, return: data } = response.data;
                    if (!error && data) {
                        this.games = data.games;
                    }
                })
            },
            loadResults(){
                GameService.results()
                .then(response => {
                    const { error, return: data } = response.data;
                    if (!error && data) {
                        this.results = data.results;
                        this.pageLoading = false;
                    }
                });
            },
            publishResult(){
                this.formProcessing = true;
                const gameId = this.selectedGameId;
                const gameDate = this.selectedDate;
                const winningNumber = this.selectedWinningNumber;

                if(gameId === null || gameDate === null || winningNumber === null){
                    alert('Please select game, date and winning number');
                }
                else
                {
                    GameService.publishResult({
                        gameId: gameId,
                        gameDate: gameDate,
                        winningNumber: winningNumber,
                    })
                    .then(response => {
                        const { error, return:data } = response.data;
                        if(!error && data){
                            this.formProcessing = false;
                            this.results.unshift(data.result);
                            this.selectedGameId = null;
                            this.selectedDate = null;
                            this.selectedWinningNumber = null;
                            this.flash('Result published successfully.', 'success', {
                                timeout: 5000,
                            });
                        }
                        else if(error && !data && error.code === 409){
                            alert('Result already exists!');
                        }
                    });
                }
            },
            restoreResult(resultId){
                GameService.restore({
                    resultId: resultId,
                })
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.results.splice(this.results.findIndex(result => result._id === resultId), 1);
                        this.flash('Game result restored successfully.', 'success', {
                            timeout: 5000,
                        });
                    }
                });
            }
        },
        created(){
            this.loadLastTenDays();
            this.loadGames();
            this.loadResults();
            this.selectedDate = this.lastTenDays[0].value;
        }
    }
</script>

<style scoped>

</style>