<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">{{ pageTitle }}</h1>
      <div class="d-flex" v-if="withdrawalRequestType !== 'PAYTM'">
        <a
          class="btn btn-primary mr-2"
          target="_blank"
          :href="getDownloadLink('xlsx')"
          >InstantPay Xlsx</a
        >
        <a
          class="btn btn-primary"
          target="_blank"
          :href="getDownloadLink('csv')"
          >RazorPay Csv</a
        >
      </div>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table
            v-if="withdrawalRequestType === 'BANK'"
            class="table table-bordered table-responsive-sm"
          >
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Wallet</th>
                <th scope="col">Withdrawal Amount</th>
                <th scope="col">Account Holder</th>
                <th scope="col">Account Number</th>
                <th scope="col">IFSC Code</th>
                <th scope="col">Requested</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :style="[
                  request.user.highlighted
                    ? { background: '#fb8888' }
                    : { background: 'transparent' },
                ]"
                v-for="(request, index) in getBankWithdrawalRequests"
                :key="index"
              >
                <td>#{{ index + 1 }}</td>
                <td>{{ request.user.name }}</td>
                <td>{{ request.user.wallet }}</td>
                <td>{{ request.amount }}</td>
                <td>{{ request.data.name }}</td>
                <td>{{ request.data.account }}</td>
                <td>{{ request.data.ifsc }}</td>
                <td>{{ moment(request.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="withdrawalRequestStatus(request._id, 'ACCEPT')"
                    class="btn btn-success btn-sm"
                  >
                    Accept
                  </button>
                  |
                  <button
                    @click="withdrawalRequestStatus(request._id, 'REJECT')"
                    class="btn btn-danger btn-sm"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            v-if="withdrawalRequestType === 'PAYTM'"
            class="table table-bordered table-responsive-sm"
          >
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Wallet</th>
                <th scope="col">Withdrawal Amount</th>
                <th scope="col">Paytm Number</th>
                <th scope="col">Requested</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :style="[
                  request.user.highlighted
                    ? { background: '#fb8888' }
                    : { background: 'transparent' },
                ]"
                v-for="(request, index) in getPaytmWithdrawalRequests"
                :key="index"
              >
                <td>#{{ index + 1 }}</td>
                <td>{{ request.user.name }}</td>
                <td>{{ request.user.wallet }}</td>
                <td>{{ request.amount }}</td>
                <td>{{ request.data.mobile }}</td>
                <td>{{ moment(request.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="withdrawalRequestStatus(request._id, 'ACCEPT')"
                    class="btn btn-success btn-sm"
                  >
                    Accept
                  </button>
                  |
                  <button
                    @click="withdrawalRequestStatus(request._id, 'REJECT')"
                    class="btn btn-danger btn-sm"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <table
            v-if="withdrawalRequestType === 'UPI'"
            class="table table-bordered table-responsive-sm"
          >
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Wallet</th>
                <th scope="col">Withdrawal Amount</th>
                <th scope="col">Upi Id</th>
                <th scope="col">Requested</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :style="[
                  request.user.highlighted
                    ? { background: '#fb8888' }
                    : { background: 'transparent' },
                ]"
                v-for="(request, index) in getUpiWithdrawalRequests"
                :key="index"
              >
                <td>#{{ index + 1 }}</td>
                <td>{{ request.user.name }}</td>
                <td>{{ request.user.wallet }}</td>
                <td>{{ request.amount }}</td>
                <td>{{ request.data.upi }}</td>
                <td>{{ moment(request.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="withdrawalRequestStatus(request._id, 'ACCEPT')"
                    class="btn btn-success btn-sm"
                  >
                    Accept
                  </button>
                  |
                  <button
                    @click="withdrawalRequestStatus(request._id, 'REJECT')"
                    class="btn btn-danger btn-sm"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import WithdrawalService from "../services/WithdrawalService";
export default {
  name: "WithdrawalRequests",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      withdrawalRequestType: this.$route.params.type.toUpperCase(),
      withdrawals: [],
    };
  },
  methods: {
    loadWithdrawals() {
      WithdrawalService.all().then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.withdrawals = data.withdrawals;
          this.pageLoading = false;
        }
      });
    },
    withdrawalRequestStatus(id, status) {
      WithdrawalService.update({
        transactionId: id,
        status: status,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.withdrawals.splice(
            this.withdrawals.findIndex((withdrawal) => withdrawal._id === id),
            1
          );
        }
      });
    },
    getDownloadLink(file) {
      const type = this.withdrawalRequestType.toLowerCase();
      return this.$baseUrl + "admin/download?type=" + type + "&file=" + file;
    },
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
    pageTitle() {
      const withdrawalType = this.withdrawalRequestType.toLowerCase();
      return (
        withdrawalType.charAt(0).toUpperCase() +
        withdrawalType.slice(1) +
        " Withdrawal Request"
      );
    },
    getBankWithdrawalRequests() {
      const withdrawalRequests = this.withdrawals.filter(
        (withdrawal) => withdrawal.data.type === "BANK"
      );
      return withdrawalRequests;
    },
    getPaytmWithdrawalRequests() {
      const withdrawalRequests = this.withdrawals.filter(
        (withdrawal) => withdrawal.data.type === "PAYTM"
      );
      return withdrawalRequests;
    },
    getUpiWithdrawalRequests() {
      const withdrawalRequests = this.withdrawals.filter(
        (withdrawal) => withdrawal.data.type === "UPI"
      );
      return withdrawalRequests;
    },
  },
  created() {
    this.loadWithdrawals();
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.withdrawalRequestType = newValue.type.toUpperCase();
        this.loadWithdrawals();
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
