<template>
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-8 m-auto">
                <h2 class="text-center font-weight-bold">Something went wrong, Please try again later.</h2>
                <p class="text-center">Error Code: {{ status.code }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState({
                status: state => state.api.status
            })
        }
    }
</script>

<style scoped>

</style>