<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Settings</h1>
            <a name="" id="" class="" href="#" role="button"></a>
            <router-link
            class="btn btn-primary mr-2"
            to="/deposit-timing"
            >Deposit Timing</router-link>
            <router-link
            class="btn btn-primary"
            to="/withdrawal-timing"
            >Withdrawal Timing</router-link
          >
        </div>

        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Description</th>
                                <th scope="col">Status</th>
                                <th scope="col">Created</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(setting, index) in settings" :key="index">
                                <td>#{{ index + 1 }}</td>
                                <td>{{ setting.name }}</td>
                                <td>{{ setting.description }}</td>
                                <td>{{ settingValue(setting.value) }}</td>
                                <td>{{ moment(setting.createdAt).format('L') }}</td>
                                <td>
                                    <div v-if="typeof setting.value === 'boolean'">
                                        <label class="switch">
                                            <input type="checkbox" :checked="setting.value" v-on:input="updateSetting(setting._id, $event)">
                                            <div class="slider round">
                                                <span class="on">ON</span>
                                                <span class="off">OFF</span>
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="typeof setting.value === 'string' || typeof setting.value === 'number'">
                                        <input :ref="setting._id" v-if="typeof setting.value === 'string'" type="text" :value="setting.value">
                                        <input :ref="setting._id" v-if="typeof setting.value === 'number'" type="number" :value="setting.value">
                                        <button class="btn btn-primary btn-sm" @click="updateSetting(setting._id, $event)">Update</button>
                                    </div>                                   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Page>
</template>

<script>
    import Page from '../components/Page'
    import SettingService  from '../services/SettingService'

    export default {
        components: {
            Page
        },
        data() {
            return {
                pageLoading: true,
                settings: [],
            }
        },
        computed: {
            isPageLoading(){
                return this.pageLoading;
            },
        },
        methods: {
            loadSettings(){
                SettingService.all()
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.settings = data.settings;
                    }
                    this.pageLoading = false;
                });
            },
            updateSetting(id, event){
                const { type, checked } = event.target;
                let creds = {
                    settingId: id,
                    value: null,
                }
                
                if(type === 'checkbox'){
                    creds.value = checked;
                }
                else{
                    const { type:inputType, value:inputValue } = this.$refs[id][0];
                    creds.value = inputType === 'number' ? parseInt(inputValue) : inputValue;
                }

                SettingService.update(creds)
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.$set(this.settings, this.settings.findIndex(setting => setting._id === id), data.setting);
                    }
                })
            },
            settingValue(value){
                let val = '';
                if(typeof value === 'boolean'){
                    val = value === true ? 'Enabled' : 'Disabled';
                }
                else{
                    val = value;
                }

                return val;
            }
        },
        created(){
            this.loadSettings()
        }
    }
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 90px;
  height: 34px;
}

.switch input {display:none;}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(55px);
  -ms-transform: translateX(55px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.on
{
  display: none;
}

.on, .off
{
  color: white;
  position: absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked+ .slider .on
{display: block;}

input:checked + .slider .off
{display: none;}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;}
</style>