<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Deposit Timing</h1>
        </div>

        <div class="container-fluid mt-4">
            <h2 class="text-center text-success mb-5">Deposit Timing: <b>{{ timing.startTime }} - {{ timing.endTime }}</b></h2>
            <div class="row">
                <div class="col-4 m-auto">
                    <form @submit.prevent="updateDepositTiming">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Start Time</label>
                            <vue-timepicker :format="timePicker.format" v-model="timePicker.startTime" :minute-interval="15"></vue-timepicker>
                            <small v-if="errors.startTime !== ''" class="form-text text-danger">{{ errors.startTime }}</small>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputPassword1">End Time</label>
                            <vue-timepicker :format="timePicker.format" v-model="timePicker.endTime" :minute-interval="15"></vue-timepicker>
                            <small v-if="errors.endTime !== ''" class="form-text text-danger">{{ errors.endTime }}</small>
                        </div>
                        <div class="form-group form-check">
                            <input type="checkbox" v-model="playableTomorrow" class="form-check-input">
                            <label class="form-check-label" for="exampleCheck1">Game is playable till next day</label>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Update</button>
                    </form>
                </div>
            </div>
        </div>
    </Page>
</template>

<script>
    import Page from '../components/Page'
    import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
    import TimingService from '../services/TimingService'
    export default {
        components: {
            Page,
            VueTimepicker
        },
        data(){
            return {
                pageLoading: true,
                errors: {
                    startTime: '',
                    endTime: '',
                },
                timing: {
                    startTime: '',
                    endTime: '',
                },
                playableTomorrow: false,
                timePicker: {
                    format: 'hh:mm A',
                    startTime: {
                        hh: '',
                        mm: '',
                        A: ''
                    },
                    endTime: {
                        hh: '',
                        mm: '',
                        A: ''
                    }
                },
            }
        },
        computed: {
            isPageLoading(){
                return this.pageLoading;
            }
        },
        methods: {
            getDepositTiming(){
                TimingService.find({
                    name: 'DEPOSIT_TIMING'
                })
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        const { startTime, endTime, playableTomorrow } = data.timing;
                        this.timing.startTime = startTime;
                        this.timing.endTime = endTime;
                        this.timePicker.startTime = this.gameTimeToObj(startTime);
                        this.timePicker.endTime = this.gameTimeToObj(endTime);
                        this.playableTomorrow = playableTomorrow;
                    }
                    else{
                        this.flash('Something went wrong, Please try again later.', 'error', { timeout: 5000 });
                    }
                    this.pageLoading = false;
                });
            },
            updateDepositTiming(){
                const startTimeObj = this.timePicker.startTime;
                const endTimeObj = this.timePicker.endTime;
                const startTimeString = startTimeObj.hh + ':' + startTimeObj.mm + ' ' + startTimeObj.A;
                const endTimeString = endTimeObj.hh + ':' + endTimeObj.mm + ' ' + endTimeObj.A;
                this.errors.startTime = '';
                this.errors.endTime = '';
                if(startTimeObj.hh === '' && startTimeObj.mm === ''){
                    this.errors.startTime = 'Deposit start time cannot be empty.';
                }
                else if(endTimeObj.hh === '' && endTimeObj.mm === ''){
                    this.errors.endTime = 'Deposit end time cannot be empty.';
                }
                else{

                    TimingService.update({
                        name: 'DEPOSIT_TIMING',
                        startTime: startTimeString,
                        endTime: endTimeString,
                        playableTomorrow: this.playableTomorrow,
                    })
                    .then(response => {
                        const { error, return:data } = response.data;
                        if(!error && data){
                            const { startTime, endTime, playableTomorrow } = data.timing;
                            this.timing.startTime = startTime;
                            this.timing.endTime = endTime;
                            this.timePicker.startTime = this.gameTimeToObj(startTime);
                            this.timePicker.endTime = this.gameTimeToObj(endTime);
                            this.playableTomorrow = playableTomorrow;
                        }
                    })
                    .catch(error => {
                        this.flash('Something went wrong, Please try again later.', 'error', { timeout: 5000 });
                    })

                }
            },
            gameTimeToObj(str){
                let time = {
                    hh: '',
                    mm: '',
                    A: ''
                };
                const firstSplit = str.split(" ");
                time.A = firstSplit[1];
                const secondSplit = firstSplit[0].split(":");
                time.hh = secondSplit[0];
                time.mm = secondSplit[1];
                return time;
            }
        },
        created(){
            this.getDepositTiming();
        }

    }
</script>

<style scoped>

</style>