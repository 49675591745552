import ApiService from './ApiService';

export default {
    all(){
        return ApiService.get('/tickets');
    },
    update(creds){
        return ApiService.put('/ticket', creds);
    },
    delete(creds){
        return ApiService.delete('/ticket/delete', {
            data: creds,
        });
    }
}