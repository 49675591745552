<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Winners</h1>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-6 m-auto text-center">
          <form @submit.prevent="getWinnersByDate">
            <select v-model="selectedDate">
              <option :value="null" disabled>Select Game Date</option>
              <option
                v-for="day in lastTenDays"
                :key="day.value"
                :value="day.value"
                >{{ day.label }}</option
              >
            </select>
            <select v-model="selectedGameId">
              <option :value="null" disabled>Select Game</option>
              <option v-for="game in games" :key="game._id" :value="game._id">{{
                game.name
              }}</option>
            </select>
            <button
              type="submit"
              class="btn btn-primary"
              style="padding: 0px 10px !important;"
            >
              Search
            </button>
          </form>
        </div>
      </div>
    </div>

    <div class="container-fluid mb-5">
      <div class="col-md-12 mt-4">
        <div class="mb-4 rounded text-center" style="background: #3490DC">
          <h1 class="p-3 text-white">{{ title }}</h1>
        </div>
        <table class="table table-bordered table-responsive-sm">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">Name</th>
              <th scope="col">Mobile</th>
              <th scope="col">Game</th>
              <th scope="col">Type</th>
              <th scope="col">Number</th>
              <th scope="col">Amount</th>
              <th scope="col">Winning Amount</th>
            </tr>
          </thead>
          <tbody v-if="winners.length">
            <tr v-for="(winner, index) in winners" :key="index">
              <td>#{{ index + 1 }}</td>
              <td>{{ winner.name }}</td>
              <td>{{ winner.mobile }}</td>
              <td>{{ winner.game }}</td>
              <td>{{ winner.type }}</td>
              <td>{{ winner.number }}</td>
              <td>{{ winner.amount }}</td>
              <td>{{ winner.winningAmount }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-danger" valign="top" colspan="8">
                No winners available in the table!
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import GameService from "../services/GameService";
export default {
  name: "Winners",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      title: "",
      lastTenDays: [],
      games: [],
      winners: [],
      selectedDate: null,
      selectedGameId: null,
    };
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
  },
  methods: {
    loadLastTenDays() {
      const NUM_OF_LAST_DAYS = 7;
      for (let i = 0; i < NUM_OF_LAST_DAYS; i++) {
        let moment = this.moment();
        const date = moment.subtract(i, "day");
        const label = date.format("dddd, Do MMMM");
        const value = date.format("YYYY-MM-DD");
        this.lastTenDays.push({
          label: label,
          value: value,
        });
      }
    },
    loadApiData() {
      GameService.all()
        .then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.games = data.games;
          }
        })
        .then(() => {
          const todaysDate = this.moment().format("YYYY-MM-DD");

          GameService.winners({
            date: todaysDate,
            gameId: this.games[0]._id,
          }).then((response) => {
            const { error, return: data } = response.data;
            if (!error && data) {
              this.title = data.title;
              this.winners = data.winners;
              this.pageLoading = false;
            }
          });
        });
    },
    getWinnersByDate(){
        if(this.selectedDate === null || this.selectedGameId === null){
            alert('Please select date and game!');
        }
        else{
            GameService.winners({
            date: this.selectedDate,
            gameId: this.selectedGameId,
          }).then((response) => {
            const { error, return: data } = response.data;
            if (!error && data) {
              this.title = data.title;
              this.winners = data.winners;
            }
          });
        }
    }
  },
  created() {
    this.loadLastTenDays();
    this.loadApiData();
    this.selectedDate = this.lastTenDays[0].value;
  },
};
</script>

<style scoped></style>
