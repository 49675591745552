import ApiService from './ApiService';

export default {
    getDepositRequests(){
        return ApiService.get('/deposit/requests');
    },
    updateDepositRequest(creds){
        return ApiService.put('/deposit/request', creds);
    },
    getDepositHistory(creds){
        return ApiService.post('/deposit-history', creds);
    },
    delete(creds){
        return ApiService.delete('/deposit/delete', {
            data: creds,
        });
    }
}