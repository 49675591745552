<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Push Notification</h1>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-6 m-auto text-center border">
          <h3 class="mt-3">Publish Game Result</h3>
          <form class="px-3 pb-2 pt-2" @submit.prevent="sendNotification">
            <div class="form-group">
              <input
                class="form-control"
                v-model="title"
                type="text"
                placeholder="Notification Title"
              />
            </div>
            <div class="form-group">
              <input
                class="form-control"
                v-model="body"
                type="text"
                placeholder="Notification Body"
              />
            </div>
            <button class="btn btn-primary btn-block" :disabled="formProcessing">{{ formProcessing ? '...' : 'Publish' }}</button>
          </form>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from "../components/Page";
import AuthService from "../services/AuthService";
export default {
  name: "PushNotification",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: false,
      title: null,
      body: null,
      formProcessing: false,
    };
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
  },
  methods: {
    sendNotification() {
      if (this.title === null || this.body === null) {
        alert("Please fill all the fields!");
      } else {
        this.formProcessing = true;
        AuthService.sendPushNotification({
          title: this.title,
          body: this.body,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.formProcessing = false;
            this.title = null;
            this.body = null;
            this.flash(
              "Push notifications send to all users successfully.",
              "success",
              {
                timeout: 5000,
              }
            );
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
