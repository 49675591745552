import ApiService from './ApiService';

export default {
    all(){
        return ApiService.get('/withdrawals');
    },
    update(creds){
        return ApiService.put('/withdrawal', creds);
    },
    history(creds){
        return ApiService.post('/withdrawal-history', creds);
    }
}