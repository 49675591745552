import ApiService from './ApiService';

export default {
    all(){
        return ApiService.get('/games');
    },
    create(creds){
        return ApiService.post('/games', creds);
    },
    update(creds){
        return ApiService.put('/game', creds);
    },
    delete(creds){
        return ApiService.delete('/game', {
            data: creds
        });
    },
    getGameBets(creds){
        return ApiService.post('/bets', creds);
    },
    jantri(creds){
        return ApiService.post('/jantri', creds);
    },
    publishResult(creds){
        return ApiService.post('/results', creds);
    },
    results(){
        return ApiService.get('/results');
    },
    restore(creds){
        return ApiService.delete('/result/restore', {
            data: creds,
        });
    },
    winners(creds){
        return ApiService.post('/winners', creds);
    }
}