<template>
        <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Links</h1>
        </div>

        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Name</th>
                                <th scope="col">Label</th>
                                <th scope="col">Url</th>
                                <th scope="col">Type</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(link, index) in links" :key="index">
                                <td>#{{ index + 1 }}</td>
                                <td>{{ link.name }}</td>
                                <td>{{ link.label }}</td>
                                <td>{{ link.url }}</td>
                                <td>{{ link.type }}</td>
                                <td>
                                    <button @click="editLink(link)" class="btn btn-primary btn-sm">Edit</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <Modal v-if="isModalOpen" :showModal="isModalOpen" :title="'Edit Link'">
            <form @submit.prevent="submitLink">
                <div class="form-group">
                    <label for="exampleInputEmail1">Label</label>
                    <input v-model="linkLabel" type="text" class="form-control" aria-describedby="emailHelp" placeholder="Link Label">
                    <!-- <small v-if="errors.name !== ''" class="form-text text-danger">{{ errors.name }}</small> -->
                </div>
                <div class="form-group">
                    <label for="exampleInputEmail1">Url</label>
                    <input v-model="linkUrl" type="text" class="form-control" aria-describedby="emailHelp" placeholder="Link Url">
                    <!-- <small v-if="errors.name !== ''" class="form-text text-danger">{{ errors.name }}</small> -->
                </div>
                <button type="submit" class="btn btn-primary btn-block">Update</button>
            </form>
        </Modal>
    </Page>
</template>

<script>
    import Page from '../components/Page';
    import LinkService from '../services/LinkService';
    export default {
        name: 'Links',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: true,
                links: [],
                openModal: false,
                linkId: null,
                linkLabel: null,
                linkUrl: null,
            }
        },
        computed: {
            isPageLoading(){
                return this.pageLoading;
            },
            isModalOpen(){
                return this.openModal;
            },
        },
        methods: {
            hideModal(){
                this.linkId = null;
                this.linkLabel = null;
                this.linkUrl = null;
                this.openModal = false;
            },
            loadLinks(){
                LinkService.all()
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.links = data.links;
                        this.pageLoading = false;
                    }
                });
            },
            editLink(link){
                this.linkLabel = link.label;
                this.linkUrl = link.url;
                this.linkId = link._id;
                this.openModal = true;
            },
            submitLink(){
                if(this.linkId === null || this.linkLabel === null || this.linkUrl === null){
                    alert('Link label and url cannot be empty!');
                }
                else{
                    LinkService.update({
                        linkId: this.linkId,
                        linkLabel: this.linkLabel,
                        linkUrl: this.linkUrl,
                    })
                    .then(response => {
                        const { error, return:data } = response.data;
                        if(!error && data){
                            this.$set(this.links, this.links.findIndex(link => link._id === this.linkId), data.link);
                            this.linkId = null;
                            this.linkLabel = null;
                            this.linkUrl = null;
                            this.flash('Link updated successfully.', 'success', {
                                timeout: 5000,
                            });
                            this.hideModal();
                        }
                    });
                }
            }
        },
        created(){
            this.$eventBus.$on('hideModal', this.hideModal);
            this.loadLinks();
        },
        beforeDestroy(){
            this.$eventBus.$off('hideModal');
        }
    }
</script>

<style scoped>

</style>