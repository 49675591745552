<template>
    <div class="container h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="card col-md-4 col-sm-6">
                <form class="p-4 box" @submit.prevent="handleLogin">
                    <h2 class="text-center text-dark font-weight-bolder mb-3">Admin Login</h2>
                    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="inputs.username" placeholder="Username">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" v-model="inputs.password" placeholder="Password">
                    </div>
                    <button class="btn btn-primary btn-block">Login</button>            
                </form>
            </div>
        </div>
    </div>
</template>

<script>

    import AuthService from '../services/AuthService';

    export default {
        data(){
            return {
                error: false,
                inputs: {
                    username: null,
                    password: null,
                }
            }
        },
        methods: {
            handleLogin(){
                this.error = false;

                const { username, password } = this.inputs;

                if(username === null || password === null){
                    this.error = 'Please fill all the fields.';
                    return;
                }
                else{
                    AuthService.login({
                        username: username,
                        password: password,
                    })
                    .then(response => {
                        const { error, return:data } = response.data;
                        if(!error && data){
                            this.$helpers.setAuthToken(data.token);
                            window.location.reload();
                        }
                        else{
                            this.error = 'Please provide valid credentials';
                        } 
                    });
                }
            }
        },
        created(){
            if(this.$helpers.isAuthTokenExists()){
                this.$router.push('/');
            }
        }
    }
</script>

<style scoped>

</style>