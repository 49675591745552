<template>
    <Page :loading="pageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Dashboard</h1>   
        </div>

    <div class="container-fluid mt-5">
        <div class="row">
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/users">
                     <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Total Users</h5>
                            <p class="text-center text-warning font-weight-bold h4">{{ totalUsersCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/users">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Total User Wallet</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ totalUsersWallet }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/deposit-requests">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Add Money Requests</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ addMoneyRequestsCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/support-tickets">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Support Tickets</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ supportTicketsCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/withdrawal-requests/bank">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Bank Withdrawals</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ bankWithdrawalsCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/withdrawal-requests/paytm">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Paytm Withdrawals</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ paytmWithdrawalsCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <router-link class="text-decoration-none" to="/withdrawal-requests/upi">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Upi Withdrawals</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ upiWithdrawalsCount }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-md-3 mb-4">
                <a href="/admin/deposits" class="text-decoration-none">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Total Deposit Today</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ totalDepositToday }}</p>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-md-3 mb-4">
                <a href="" class="text-decoration-none">
                    <div class="card playindia_card">
                        <div class="card-body">
                            <h5 class="card-title text-center text-muted font-weight-bold h4">Total Withdrawals Today</h5>
                            <p class="text-center text-warning  font-weight-bold h4">{{ totalWithdrawalToday }}</p>
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="card playindia_card">
                    <div class="card-body text-center">
                        <h5 class="card-title text-muted font-weight-bold h4">Site Backup</h5>
                        <a class="btn btn-primary" target="_blank" :href="getBackupUrl">
                            Download
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card playindia_card">
                    <div class="card-body text-center">
                        <h5 class="card-title text-muted font-weight-bold h4">Results</h5>
                        <router-link class="btn btn-primary" to="/results">Go To Results Page</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </Page>
</template>


<script>
import Page from '../components/Page'
import AuthService from '../services/AuthService';

export default {
    components: {
        Page,
    },
    data(){
        return {
            pageLoading: true,
            apiUrl: this.$baseUrl,
            totalUsersCount: 0,
            totalUsersWallet: 0,
            addMoneyRequestsCount: 0,
            supportTicketsCount: 0,
            bankWithdrawalsCount: 0,
            paytmWithdrawalsCount: 0,
            upiWithdrawalsCount: 0,
            totalDepositToday: 0,
            totalWithdrawalToday: 0,
        }
    },
    computed: {
        getBackupUrl(){
            return this.$baseUrl + 'admin/download?type=' + 'backup' + '&file=' + 'gz';
        }
    },
    methods: {
        loadDashboardData(){
            AuthService.getDashboardInfo()
            .then(response => {
                const { error, return:data } = response.data;
                if(!error && data){
                    this.totalUsersCount = data.totalUsersCount;
                    this.totalUsersWallet = data.totalUsersWallet;
                    this.addMoneyRequestsCount = data.addMoneyRequestsCount;
                    this.supportTicketsCount = data.supportTicketsCount;
                    this.bankWithdrawalsCount = data.bankWithdrawalsCount;
                    this.paytmWithdrawalsCount = data.paytmWithdrawalsCount;
                    this.upiWithdrawalsCount = data.upiWithdrawalsCount;
                    this.totalDepositToday = data.totalDepositToday;
                    this.totalWithdrawalToday = data.totalWithdrawalToday;
                    this.pageLoading = false;
                }
            });
        }
    },
    created(){
        this.loadDashboardData();
    }
}
</script>


<style >

</style>