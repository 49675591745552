<template>
    <Page :loading="isPageLoading">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2 w-100 text-center">Withdrawal History</h1>
        </div>

        <div class="container-fluid mt-5">
            <div class="row">
                <div class="col-12">
                    <table class="table table-bordered table-responsive-sm">
                        <thead>
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Type</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr :style="[withdrawal.user.highlighted ? {'background': '#fb8888'} : {'background': 'transparent'}]" v-for="withdrawal in getWithdrawals" :key="withdrawal._id">
                                <td>{{ moment(withdrawal.createdAt).format('DD/MM/YYYY h:mm A') }}</td>
                                <td>{{ withdrawal.user.name }}</td>
                                <td>{{ withdrawal.user.mobile }}</td>
                                <td>{{ withdrawal.data.type }}</td>
                                <td>{{ withdrawal.amount }}</td>
                                <td v-if="withdrawal.data.type === 'BANK'">
                                    <p>Account Name: {{ withdrawal.data.name }}</p>
                                    <p>Account Number: {{ withdrawal.data.account }}</p>
                                    <p>IFSC Code: {{ withdrawal.data.ifsc }}</p>
                                </td>
                                <td v-if="withdrawal.data.type === 'PAYTM'">
                                    <p>Mobile Number: {{ withdrawal.data.mobile }}</p>
                                </td>
                                <td v-if="withdrawal.data.type === 'UPI'">
                                    <p>Upi Id: {{ withdrawal.data.upi }}</p>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>
                     <nav v-if="totalCount > perPage" aria-label="Page navigation">
                        <ul class="pagination flex-wrap float-right">
              <li class="page-item" v-if="page !== 1" @click="page--">
                <a class="page-link" href="#">«</a>
              </li>
                <li
                  class="page-item"
                  :class="{ active: pageNumber === page }"
                  v-for="pageNumber in pagination(page, totalPagesCount)"
                  :key="pageNumber"
                  @click="page = pageNumber"
                >
                  <a class="page-link" href="#">{{ pageNumber }}</a>
                </li>
              <li
                class="page-item"
                v-if="page < totalPagesCount"
                @click="page++"
              >
                <a class="page-link" href="#">»</a>
              </li>
            </ul>
                    </nav>
                </div>
            </div>
        </div>
    </Page>
</template>

<script>
import Page from "../components/Page";
import WithdrawalService from '../services/WithdrawalService';
    export default {
        name: 'WithdrawalHistory',
        components: {
            Page,
        },
        data(){
            return {
                pageLoading: true,
                withdrawals: [],
                page: 1,
                perPage: 10,
                totalCount: 0,
            }
        },
        computed: {
            getWithdrawals(){
                return this.withdrawals;
            },
            isPageLoading(){
                return this.pageLoading;
            },
            totalPagesCount(){
                return Math.ceil(this.totalCount / this.perPage);
            },
        },
        methods: {
            loadWithdrawals(limit, skip){
                WithdrawalService.history({
                    limit: limit,
                    skip: skip,
                })
                .then(response => {
                    const { error, return:data } = response.data;
                    if(!error && data){
                        this.withdrawals = data.withdrawals;
                        this.totalCount = data.totalWithdrawalsCount;
                        this.pageLoading = false;
                    }
                });
            },
            paginateWithdrawals(newPageNumber, oldPageNumber){
                
                let limit = this.perPage,
                    skip = (this.page * this.perPage) - this.perPage;
                
                this.pageLoading = true;
                this.loadWithdrawals(limit, skip);
                
            },
            pagination(c, m) {
      var current = c,
        last = m,
        delta = 3,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [],
        l;

      for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || (i >= left && i < right)) {
          range.push(i);
        }
      }

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
            
        },
        watch: {
            page(newPageNumber, oldPageNumber){
                this.paginateWithdrawals(newPageNumber, oldPageNumber);
            }
        },
        created(){
            this.loadWithdrawals(this.perPage, 0);
        }

    }
</script>

<style scoped>

</style>