<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Support Tickets</h1>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered table-responsive-sm">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Mobile</th>
                <th scope="col">Problem</th>
                <th scope="col">Description</th>
                <th scope="col">Screenshot</th>
                <th scope="col">Ticket Created</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr :style="[ticket.user.highlighted ? {'background': '#fb8888'} : {'background': 'transparent'}]" v-for="(ticket, index) in tickets" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ ticket.user.name }}</td>
                <td>{{ ticket.user.mobile }}</td>
                <td>{{ ticket.problem }}</td>
                <td>{{ ticket.description }}</td>
                <td v-if="ticket.screenshot === ''">Not Given</td>
                <td v-else>
                  <a
                    target="_blank"
                    :href="getScreenshotUrl(ticket.screenshot)"
                  >
                    <img
                      class="screenshot_img"
                      :src="getScreenshotUrl(ticket.screenshot)"
                      alt=""
                    />
                  </a>
                </td>
                <td>{{ moment(ticket.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="openResponseModal(ticket._id)"
                    class="btn btn-success btn-sm"
                  >
                    Respond
                  </button>
                  <button
                    @click="openAddMoneyModal(ticket.userId)"
                    class="btn btn-success btn-sm ml-2"
                  >
                    Add Money
                  </button>
                  <button
                    type="button"
                    @click="deleteTicket(index)"
                    class="btn btn-danger btn-sm ml-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Modal
      v-if="isResponseModalOpen"
      :showModal="isResponseModalOpen"
      :title="'Ticket Response'"
    >
      <form @submit.prevent="submitTicket">
        <div class="form-group">
          <label for="exampleInputEmail1">Response</label>
          <textarea
            class="form-control"
            v-model="response"
            placeholder="Support Ticket Response"
          ></textarea>
        </div>
        <input type="hidden" v-model="ticketModalId" />
        <button type="submit" class="btn btn-primary btn-block">Done</button>
      </form>
    </Modal>

    <Modal
      v-if="isAddMoneyModalOpen"
      :showModal="isAddMoneyModalOpen"
      :title="'Add Money To Wallet'"
    >
      <form @submit.prevent="addMoneyToUserWallet">
        <div class="form-group">
          <label for="exampleInputPassword1">Amount</label>
          <input
            type="number"
            v-model="addMoneyAmount"
            class="form-control"
            aria-describedby="helpId"
            placeholder="Amount"
          />
        </div>
        <button type="submit" class="btn btn-primary btn-block">
          Add Money
        </button>
      </form>
    </Modal>
  </Page>
</template>

<script>
import Page from "../components/Page";
import TicketService from "../services/TicketService";
import UserService from "../services/UserService";

export default {
  name: "SupportTicket",
  components: {
    Page,
  },
  data() {
    return {
      pageLoading: true,
      tickets: [],
      responseModal: false,
      response: "",
      ticketModalId: "",
      addMoneyModal: false,
      addMoneyUserId: "",
      addMoneyAmount: null,
    };
  },
  computed: {
    isPageLoading() {
      return this.pageLoading;
    },
    isResponseModalOpen() {
      return this.responseModal;
    },
    isAddMoneyModalOpen() {
      return this.addMoneyModal;
    },
  },
  methods: {
    getScreenshotUrl(screenshot) {
      return this.$baseUrl + "tickets/" + screenshot;
    },
    getAllTickets() {
      TicketService.all().then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.tickets = data.tickets;
        }
        this.pageLoading = false;
      });
    },
    openResponseModal(id) {
      this.ticketModalId = id;
      this.responseModal = true;
    },
    openAddMoneyModal(id) {
      this.addMoneyUserId = id;
      this.addMoneyModal = true;
    },
    hideModal() {
      this.responseModal = false;
      this.addMoneyModal = false;
    },
    submitTicket() {
      const ticketId = this.ticketModalId;
      TicketService.update({
        ticketId: ticketId,
        response: this.response,
      }).then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          const ticketIndex = this.tickets.findIndex(
            (ticket) => ticket._id === ticketId
          );
          this.tickets.splice(ticketIndex, 1);
          this.flash(`Ticket responded successfully.`, "success", {
              timeout: 5000,
            });
        }
        this.responseModal = false;
      });
    },
    addMoneyToUserWallet() {
      if (this.addMoneyAmount === null) {
        alert("Amount cannot be empty!");
      } else {
        UserService.walletAction({
          userId: this.addMoneyUserId,
          action: "ADD",
          amount: this.addMoneyAmount,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.flash(`Money added to wallet successfully.`, "success", {
              timeout: 5000,
            });
            this.hideModal();
          }
        });
      }
    },
    deleteTicket(index) {
      if (confirm("Are you sure you want to delete this ticket?")) {
        TicketService.delete({
          ticketId: this.tickets[index]._id,
        }).then((response) => {
          const { error, return: data } = response.data;
          if (!error && data) {
            this.tickets.splice(index, 1);
            this.flash("Ticket deleted successfully.", "success", {
                timeout: 5000,
              });
          }
        });
      }
    },
  },
  created() {
    this.$eventBus.$on("hideModal", this.hideModal);
    this.getAllTickets();
  },
  beforeDestroy() {
    this.$eventBus.$off("hideModal");
  },
};
</script>

<style scoped>
.screenshot_img {
  width: 100px;
  height: 100px;
}
</style>
