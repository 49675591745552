<template>
     <nav class="navbar navbar-dark sticky-top flex-md-nowrap p-0 shadow" style="background-color: #121833;">
    <a class="navbar-brand col-md-3 col-lg-2 mr-0 px-3 text-center" href="/admin/dashboard" style="font-size: 18px;font-weight: 900;">GoWin</a>
    <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <ul class="navbar-nav px-3 flex-row">
      <li class="nav-item text-nowrap">
          <span class="btn text-white">Online Users: {{ getTotalOnlineUsers }}</span>
      </li>
      <li class="nav-item text-nowrap">
        <a class="btn btn-link" @click="logout">Logout</a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
    export default {
      computed: {
          ...mapGetters({
              getTotalOnlineUsers: 'admin/onlineUsersCount'
          })
        },
      methods: {
          logout(){
              this.$helpers.deleteAuthToken();
              this.$router.push('/login');
          }
        }
    }
</script>

<style scoped>

</style>