<template>
  <Page :loading="isPageLoading">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
    >
      <h1 class="h2 w-100 text-center">Games</h1>
      <button @click="showModal">Create</button>
    </div>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-12">
          <table class="table table-bordered table-responsive-sm">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Game Name</th>
                <th scope="col">Start Time</th>
                <th scope="col">End Time</th>
                <th scope="col">Game Created</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(game, index) in games" :key="index">
                <td>#{{ index + 1 }}</td>
                <td>{{ game.name }}</td>
                <td>{{ game.startTime }}</td>
                <td>{{ game.endTime }}</td>
                <td>{{ moment(game.createdAt).format("DD/MM/YYYY") }}</td>
                <td>
                  <button
                    @click="showEditGameModal(game)"
                    class="btn btn-primary btn-sm"
                  >
                    Edit
                  </button>
                  |
                  <button
                    @click="deleteGame(index)"
                    class="btn btn-danger btn-sm"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <Modal v-if="isModalOpen" :showModal="isModalOpen" :title="gameModalTitle">
      <form @submit.prevent="submitGameModalForm">
        <div class="form-group">
          <label for="exampleInputEmail1">Name</label>
          <input
            v-model="name"
            type="text"
            class="form-control"
            aria-describedby="emailHelp"
            placeholder="Game name"
          />
          <small v-if="errors.name !== ''" class="form-text text-danger">{{
            errors.name
          }}</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Start Time</label>
          <vue-timepicker
            :format="timePicker.format"
            v-model="timePicker.startTime"
            :minute-interval="15"
          ></vue-timepicker>
          <small v-if="errors.startTime !== ''" class="form-text text-danger">{{
            errors.startTime
          }}</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">End Time</label>
          <vue-timepicker
            :format="timePicker.format"
            v-model="timePicker.endTime"
            :minute-interval="15"
          ></vue-timepicker>
          <small v-if="errors.endTime !== ''" class="form-text text-danger">{{
            errors.endTime
          }}</small>
        </div>
        <div class="form-group form-check">
          <input
            type="checkbox"
            v-model="playableTomorrow"
            class="form-check-input"
          />
          <label class="form-check-label text-dark" for="exampleCheck1"
            >Game is playable till next day</label
          >
        </div>
        <button type="submit" class="btn btn-primary btn-block">
          {{ gameSubmitButton }}
        </button>
      </form>
    </Modal>
  </Page>
</template>

<script>
import Page from "../components/Page";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import GameService from "../services/GameService";

export default {
  name: "Games",
  components: {
    Page,
    VueTimepicker,
  },
  data() {
    return {
      pageLoading: true,
      games: [],
      gameId: "",
      openModal: false,
      errors: {
        name: "",
        startTime: "",
        endTime: "",
      },
      name: "",
      timePicker: {
        format: "hh:mm A",
        startTime: {
          hh: "02",
          mm: "00",
          A: "AM",
        },
        endTime: {
          hh: "10",
          mm: "00",
          A: "PM",
        },
      },
      playableTomorrow: false,
    };
  },
  computed: {
    isModalOpen() {
      return this.openModal;
    },
    isPageLoading() {
      return this.pageLoading;
    },
    gameModalTitle() {
      return this.gameId === "" ? "Create New Game" : "Edit Game";
    },
    gameSubmitButton() {
      return this.gameId === "" ? "Create" : "Update";
    },
  },
  methods: {
    showModal() {
      this.openModal = true;
    },
    hideModal() {
      this.resetGameModalData();
      this.openModal = false;
    },
    resetGameModalData() {
      this.gameId = "";
      this.name = "";
      this.playableTomorrow = false;
      this.errors = {
        name: "",
        startTime: "",
        endTime: "",
      };
      this.timePicker = {
        format: "hh:mm A",
        startTime: {
          hh: "02",
          mm: "00",
          A: "AM",
        },
        endTime: {
          hh: "10",
          mm: "00",
          A: "PM",
        },
      };
    },
    submitGameModalForm() {
      const startTimeObj = this.timePicker.startTime;
      const endTimeObj = this.timePicker.endTime;
      const startTimeString =
        startTimeObj.hh + ":" + startTimeObj.mm + " " + startTimeObj.A;
      const endTimeString =
        endTimeObj.hh + ":" + endTimeObj.mm + " " + endTimeObj.A;
      this.errors.name = "";
      this.errors.startTime = "";
      this.errors.endTime = "";
      if (this.name === "") {
        this.errors.name = "Game name cannot be empty.";
      } else if (startTimeObj.hh === "" && startTimeObj.mm === "") {
        this.errors.startTime = "Game start time cannot be empty.";
      } else if (endTimeObj.hh === "" && endTimeObj.mm === "") {
        this.errors.endTime = "Game end time cannot be empty.";
      } else {
        if (this.gameId === "") {
          GameService.create({
            name: this.name,
            startTime: startTimeString,
            endTime: endTimeString,
            playableTomorrow: this.playableTomorrow,
          }).then((response) => {
            const { error, return: data } = response.data;
            if (!error && data) {
              this.games.push(data.game);
              this.flash("Game created successfully.", "success", {
                timeout: 5000,
              });
              this.hideModal();
            }
          });
        } else {
          GameService.update({
            id: this.gameId,
            name: this.name,
            startTime: startTimeString,
            endTime: endTimeString,
            playableTomorrow: this.playableTomorrow,
          }).then((response) => {
            const { error, return: data } = response.data;
            if (!error && data) {
              this.$set(
                this.games,
                this.games.findIndex((game) => game._id === this.gameId),
                data.game
              );
              this.flash("Game updated successfully.", "success", {
                timeout: 5000,
              });
              this.hideModal();
            }
          });
        }
      }
    },
    showEditGameModal(game) {
      this.name = game.name;
      this.playableTomorrow = game.playableTomorrow;
      this.gameId = game._id;
      this.timePicker.startTime = this.gameTimeToObj(game.startTime);
      this.timePicker.endTime = this.gameTimeToObj(game.endTime);
      this.showModal();
    },
    loadAllGames() {
      GameService.all().then((response) => {
        const { error, return: data } = response.data;
        if (!error && data) {
          this.games = data.games;
          this.pageLoading = false;
        }
      });
    },
    gameIdButton() {
      const string = this.gameId;
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    gameTimeToObj(str) {
      let time = {
        hh: "",
        mm: "",
        A: "",
      };
      const firstSplit = str.split(" ");
      time.A = firstSplit[1];
      const secondSplit = firstSplit[0].split(":");
      time.hh = secondSplit[0];
      time.mm = secondSplit[1];
      return time;
    },
    deleteGame(index) {
      if (confirm(`Are you sure you want to delete ${this.games[index].name} game?`)) {
        GameService.delete({
          gameId: this.games[index]._id,
        }).then((response) => {
            const { error, return: data } = response.data;
            if (!error && data) {
                this.games.splice(index, 1);
                this.flash("Game deleted successfully.", "success", {
                timeout: 5000,
                });
            }
        });
      }
    },
  },
  created() {
    this.$eventBus.$on("hideModal", this.hideModal);
    this.loadAllGames();
  },
  beforeDestroy() {
    this.$eventBus.$off("hideModal");
  },
};
</script>

<style scoped></style>
